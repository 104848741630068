import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../../state.interface';
import * as instituteActions from './action/institute-management.action';

import { Subscription } from 'rxjs';
import {
    cityList,
    countryList,
    createInstitute,
    createInstituteLoading,
    instituteDetails,
    instituteList,
    instituteListLoading,
    instituteSalesMan,
    isSelect,
    pocLiveInstituteList,
    regionList,
    schoolTypeBranch,
    updateInstitute,
    instituteCount,
    pocCount,
    liveCount,
    deleteInstitute,
    uploadInstituteLogo,
    instituteApproval,
    instituteRenewal,
    uploadInstituteFile,
    standardList,
    sectionList,
    sectionStandardUpdate,
    pocLiveInstituteListLoading,
    sectionStandardUpdateLoading,
    sectionStandardUpdateLoaded,
    getSectionStandardUpdate,
    getSectionStandardUpdateLoading,
    verifyInstitute,
    additionalModuleList,
    languageList,
    approvedCount,
    approvalPendingCount,
    verificationPendingCount,
    inactiveCount,
    activeCount,
    approvalVerificationList,
    approvalVerificationListLoading,
    activeInactiveList,
    activeInactiveListLoading,
    uploadInstituteFileLoading,
    uploadInstituteLogoLoading,
    institutePaidCount,
    instituteCsrCount,
    instituteDetailsLoading,
    smsProviderList,
    regionListLoading,
    cityListLoading,
    boardList,
    allInstituteList,
    instituteSearchCount,
    instituteRenewalLoading,
    redirectUrl,
    instituteApprovalLoading, updateInstituteLoading, verifyInstituteLoading, exportInstitute, exportInstituteLoading, redirectUrlLoading,
    academicYearDetails,
    changeAcademicYearSetting,
    academicYearList,
    GetScheduledInstituteDetails,
    GetScheduledInstituteDetailsLoading,
    getDashBoardCount,
    getDashBoardCountLoading,
} from './reducer/institute-management.selector';
import { CreateInstituteModel } from './models/create.institute.model';
import { InstituteListModel } from './models/institute-list.model';
import { InstituteSalesManModel } from './models/institute.salesMan.model';
import { PocLiveListModel } from './models/pocLiveListModel';
import { UploadInstituteLogoModel } from './models/upload-institute-logo.model';
import { InstituteApprovalModel } from './models/institute.approval.model';
import { InstituteRenewalModel } from './models/institute.renewal.model';
import { UploadInstituteFileModel } from './models/upload-institute-file.model';
import { InstituteVerifyModel } from './models/verify.model';

@Injectable()

export class InstituteManagementSandbox {
    public createInstitute$ = this.appState.select(createInstitute);
    public instituteList$ = this.appState.select(instituteList);
    public instituteListLoading$ = this.appState.select(instituteListLoading);
    public createInstituteLoading$ = this.appState.select(createInstituteLoading);
    public countryList$ = this.appState.select(countryList);
    public regionList$ = this.appState.select(regionList);
    public regionListLoading$ = this.appState.select(regionListLoading);
    public cityList$ = this.appState.select(cityList);
    public cityListLoading$ = this.appState.select(cityListLoading);
    public schoolTypeBranch$ = this.appState.select(schoolTypeBranch);
    public instituteSalesMan$ = this.appState.select(instituteSalesMan);
    public instituteDetail$ = this.appState.select(instituteDetails);
    public instituteDetailsLoading$ = this.appState.select(instituteDetailsLoading);
    public updateInstitute$ = this.appState.select(updateInstitute);
    public pocLiveInstituteList$ = this.appState.select(pocLiveInstituteList);
    public pocLiveInstituteListLoading$ = this.appState.select(pocLiveInstituteListLoading);
    public isSelect$ = this.appState.select(isSelect);
    public instituteCount$ = this.appState.select(instituteCount);
    public pocCount$ = this.appState.select(pocCount);
    public liveCount$ = this.appState.select(liveCount);
    public deleteInstitute$ = this.appState.select(deleteInstitute);
    public uploadInstituteLogo$ = this.appState.select(uploadInstituteLogo);
    public instituteApproval$ = this.appState.select(instituteApproval);
    public instituteApprovalLoading$ = this.appState.select(instituteApprovalLoading);
    public instituteRenewal$ = this.appState.select(instituteRenewal);
    public instituteRenewalLoading$ = this.appState.select(instituteRenewalLoading);
    public uploadInstituteFile$ = this.appState.select(uploadInstituteFile);
    public standardList$ = this.appState.select(standardList);
    public sectionList$ = this.appState.select(sectionList);
    public sectionStandardUpdate$ = this.appState.select(sectionStandardUpdate);
    public sectionStandardUpdateLoading$ = this.appState.select(sectionStandardUpdateLoading);
    public sectionStandardUpdateLoaded$ = this.appState.select(sectionStandardUpdateLoaded);
    public getSectionStandardUpdate$ = this.appState.select(getSectionStandardUpdate);
    public getSectionStandardUpdateLoading$ = this.appState.select(getSectionStandardUpdateLoading);
    public verifyInstitute$ = this.appState.select(verifyInstitute);
    public additionalModuleList$ = this.appState.select(additionalModuleList);
    public languageList$ = this.appState.select(languageList);
    public approvedCount$ = this.appState.select(approvedCount);
    public approvalPendingCount$ = this.appState.select(approvalPendingCount);
    public verificationPendingCount$ = this.appState.select(verificationPendingCount);
    public inactiveCount$ = this.appState.select(inactiveCount);
    public activeCount$ = this.appState.select(activeCount);
    public approvalVerificationList$ = this.appState.select(approvalVerificationList);
    public approvalVerificationListLoading$ = this.appState.select(approvalVerificationListLoading);
    public activeInactiveList$ = this.appState.select(activeInactiveList);
    public activeInactiveListLoading$ = this.appState.select(activeInactiveListLoading);
    public uploadInstituteFileLoading$ = this.appState.select(uploadInstituteFileLoading);
    public uploadInstituteLogoLoading$ = this.appState.select(uploadInstituteLogoLoading);
    public institutePaidCount$ = this.appState.select(institutePaidCount);
    public instituteCsrCount$ = this.appState.select(instituteCsrCount);
    public smsProviderList$ = this.appState.select(smsProviderList);
    public boardList$ = this.appState.select(boardList);
    public allInstituteList$ = this.appState.select(allInstituteList);
    public instituteSearchCount$ = this.appState.select(instituteSearchCount);
    public updateInstituteLoading$ = this.appState.select(updateInstituteLoading);
    public verifyInstituteLoading$ = this.appState.select(verifyInstituteLoading);

    public exportInstitute$ = this.appState.select(exportInstitute);
    public exportInstituteLoading$ = this.appState.select(exportInstituteLoading);

    public redirectUrl$ = this.appState.select(redirectUrl);
    public redirectUrlLoading$ = this.appState.select(redirectUrlLoading);

    public academicYearDetails$ = this.appState.select(academicYearDetails);
    public changeAcademicYearSetting$ = this.appState.select(changeAcademicYearSetting);
    public academicYearList$ = this.appState.select(academicYearList);

    public GetScheduledInstituteDetails$ = this.appState.select(GetScheduledInstituteDetails);
    public GetScheduledInstituteDetailsLoading$ = this.appState.select(GetScheduledInstituteDetailsLoading);


    public getDashBoardCount$ = this.appState.select(getDashBoardCount);
    public getDashBoardCountLoading$ = this.appState.select(getDashBoardCountLoading);
    

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected appState: Store<store.AppState>) {
        this.subscribe();
    }
    // calling create institute Action
    createInstitute(value) {
        this.appState.dispatch(new instituteActions.CreateInstituteAction(new CreateInstituteModel(value)));
    }
    // calling update institue
    upDateInstitute(value) {
        this.appState.dispatch(new instituteActions.UpdateInstituteAction(new CreateInstituteModel(value)));
    }
    // calling institute list
    instituteList(value) {
        this.appState.dispatch(new instituteActions.InstituteListAction(new InstituteListModel(value)));
    }
    // calling country list
    countryList() {
        this.appState.dispatch(new instituteActions.CountryListAction());
    }
    // calling region list
    getRegionList(params) {
        this.appState.dispatch(new instituteActions.RegionListAction(params));
    }
    // calling city API
    getCityList(params) {
        this.appState.dispatch(new instituteActions.CityListAction(params));
    }
    // calling school type branch
    public getSchoolType() {
        this.appState.dispatch(new instituteActions.SchoolTypeBranchAction());
    }
    // calling sales man list Api
    public getSalesManList(value) {
        this.appState.dispatch(new instituteActions.InstituteSalesManAction(new InstituteSalesManModel(value)));
    }
    // calling institute detail Api
    public instituteDetail(value) {
        this.appState.dispatch(new instituteActions.InstituteDetailsAction(value));
    }
    // calling pocLive List
    public PocLiveList(value) {
        this.appState.dispatch(new instituteActions.InstitutePocLiveAction(new PocLiveListModel(value)));
    }
    // empty Empty Institute Details Variable in reducers
    public emptyInstituteDetails(value) {
        this.appState.dispatch(new instituteActions.EmptyInstituteDetailsVariable(value));
    }
    // delete institute list
    public deleteInstitute(value) {
        this.appState.dispatch(new instituteActions.DeleteInstituteAction(value));
    }
    // empty delete institute variable
    public emptyDeleteInstituteVar(value) {
        this.appState.dispatch(new instituteActions.EmptyDeleteInstituteVariable(value));
    }
    // upload institute logo
    public uploadInstituteLogo(value) {
        this.appState.dispatch(new instituteActions.UploadInstituteLogoAction(new UploadInstituteLogoModel(value)));
    }
    // approve api
    public approveInstitute(value) {
        this.appState.dispatch(new instituteActions.InstituteApprovalAction(new InstituteApprovalModel(value)));
    }
    // renewal api
    public renewalInstitute(value) {
        this.appState.dispatch(new instituteActions.InstituteRenewalAction(new InstituteRenewalModel(value)));
    }
    // upload institute File
    public uploadInstituteFile(value) {
        this.appState.dispatch(new instituteActions.UploadInstituteFileAction(new UploadInstituteFileModel(value)));
    }
    // empty Empty upload Institute file Variable in reducers
    public emptyUploadInstituteFile(value) {
        this.appState.dispatch(new instituteActions.EmptyUploadInstituteFile(value));
    }
    // get standard list
    public getStandardList() {
        this.appState.dispatch(new instituteActions.StandardListAction());
    }
    // get section list
    public getSectionList() {
        this.appState.dispatch(new instituteActions.SectionListAction());
    }
    subscribe() {
        this.subscriptions.push(this.instituteCount$.subscribe(data => {
            // console.log(data , 'data');
        }));
    }
    // clear institute List
    clearInstituteList() {
        this.appState.dispatch(new instituteActions.ClearInstituteList());
    }
    updateStandardSection(params: any) {
        this.appState.dispatch(new instituteActions.SectionSatandardUpdatetAction(params));
    }

    getStandardSection(params: any) {
        this.appState.dispatch(new instituteActions.GetSectionStandardUpdateAction(params));
    }
    // verify institute
    verifyInstitute(params: any) {
        this.appState.dispatch(new instituteActions.VerifyInstituteAction(new InstituteVerifyModel(params)));
    }
    // additional module List
    AdditionalModuleList() {
        this.appState.dispatch(new instituteActions.AdditionalModuleListAction());
    }
    // checked additional module
    isCheckedAdditionalModule(params) {
        this.appState.dispatch(new instituteActions.AdditionalCheckedAction(params));
    }
    // calling language list
    getLanguageList() {
        this.appState.dispatch(new instituteActions.LanguageListAction());
    }
    // calling verification Approval List
    verificationApprovalList(value) {
        this.appState.dispatch(new instituteActions.ApprovalVerificationAction(value));
    }
    // calling activeinactive List
    inactiveActiveList(value) {
        this.appState.dispatch(new instituteActions.ActiveInactiveAction(value));
    }
    // clear institute data
    InstituteDataClear() {
        this.appState.dispatch(new instituteActions.InstituteDataClear());
    }
    // sms Provider List
    smsProviderList() {
        this.appState.dispatch(new instituteActions.SmsProviderAction());
    }
    // institute Board List
    getBoardList() {
        this.appState.dispatch(new instituteActions.BoardListAction());
    }
    // calling all institute list
    allInstituteList(value) {
        this.appState.dispatch(new instituteActions.ALlInstituteAction(new InstituteListModel(value)));
    }
    // redirect to institute url
    redirectToInstitute(value) {
        this.appState.dispatch(new instituteActions.RedirectToInstituteAction(value));
    }
    // calling institute list count
    instituteListCount(value) {
        this.appState.dispatch(new instituteActions.InstituteListCountAction(new InstituteListModel(value)));
    }
    exportInstitute(value) {
        this.appState.dispatch(new instituteActions.ExportInstituteAction(value));
    }
    exportApprovalVerification(value) {
        this.appState.dispatch(new instituteActions.ExportVerificationAction(value));
    }
    exportCallPocList(value) {
        this.appState.dispatch(new instituteActions.ExportLivePocAction(value));
    }
    exportGovtList(value) {
        this.appState.dispatch(new instituteActions.ExportGovtAction(value));
    }
    public getAcademicYearSetting(params) {
        this.appState.dispatch(new instituteActions.GetAcademicYearSettingAction(params));
    }
    public changeAcademicYearSetting(params) {
        this.appState.dispatch(new instituteActions.ChangeAcademicYearSetting(params));
    }
    public getAcademicYearList(params) {
        this.appState.dispatch(new instituteActions.AcademicYearListAction(params));
    }
    public getScheduledInstituteDetails(value) {
        this.appState.dispatch(new instituteActions.GetScheduledInstituteDetails(value));
    }

    public getDashBoardCount(value) {
        this.appState.dispatch(new instituteActions.getDashBoardCount(value));
    }
}
