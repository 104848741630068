import { Map, Record } from 'immutable';

export interface InstituteManagementState extends Map<string, any> {
    createInstitute: any;
    instituteList: any;
    countryList: any;
    regionList: any;
    cityList: any;
    schoolTypeBranch: any;
    instituteSalesMan: any;
    instituteDetails: any;
    updateInstitute: any;
    pocLiveInstituteList: any;
    allListCount: any;
    approvedCount: any;
    verificationPendingCount: any;
    approvalPendingCount: any;
    livePocCount: any;
    InactiveCount: any;
    isSelect: any;
    pocCount: any;
    liveCount: any;
    deleteInstitute: any;
    uploadInstituteLogo: any;
    instituteApproval: any;
    instituteRenewal: any;
    uploadInstituteFile: any;
    standardList: any;
    sectionList: any;
    sectionStandardUpdate: any;
    verifyInstitute: any;
    additionalModuleList: any;
    additionalModulesData: any;
    languageList: any;
    inactiveCount: any;
    activeCount: any;
    approvalVerificationList: any;
    activeInactiveList: any;
    institutePaidCount: any;
    instituteCsrCount: any;
    smsProviderList: any;
    boardList: any;
    allInstituteList: any;
    instituteSearchCount: any;
    exportInstitute: any;
    redirectUrl: any;

    academicYearDetails: any;
    changeAcademicYearSetting: any;
    academicYearList: Array<any>;

    redirectUrlLoading: boolean;
    redirectUrlLoaded: boolean;
    redirectUrlFailed: boolean;

    exportInstituteLoading: boolean;
    exportInstituteLoaded: boolean;
    exportInstituteFailed: boolean;

    allInstituteListLoading: boolean;
    allInstituteListLoaded: boolean;
    allInstituteListFailed: boolean;

    boardListLoading: boolean;
    boardListLoaded: boolean;
    boardListFailed: boolean;

    smsProviderListLoading: boolean;
    smsProviderListLoaded: boolean;
    smsProviderListFailed: boolean;

    activeInactiveListLoading: boolean;
    activeInactiveListLoaded: boolean;
    activeInactiveListFailed: boolean;

    approvalVerificationListLoading: boolean;
    approvalVerificationListLoaded: boolean;
    approvalVerificationListFailed: boolean;

    additionalModuleListLoading: boolean;
    additionalModuleListLoaded: boolean;
    additionalModuleListFailed: boolean;

    createInstituteLoading: boolean;
    createInstituteLoaded: boolean;
    createInstituteFailed: boolean;

    countryListLoading: boolean;
    countryListLoaded: boolean;
    countryListFailed: boolean;

    instituteListLoading: boolean;
    instituteListLoaded: boolean;
    instituteListFailed: boolean;

    regionListLoading: boolean;
    regionListLoaded: boolean;
    regionListFailed: boolean;

    cityListLoading: boolean;
    cityListLoaded: boolean;
    cityListFailed: boolean;

    schoolTypeBranchLoading: boolean;
    schoolTypeBranchLoaded: boolean;
    schoolTypeBranchFailed: boolean;

    instituteSalesManLoading: boolean;
    instituteSalesManLoaded: boolean;
    instituteSalesManFailed: boolean;

    instituteDetailsLoading: boolean;
    instituteDetailsLoaded: boolean;
    instituteDetailsFailed: boolean;

    updateInstituteLoading: boolean;
    updateInstituteLoaded: boolean;
    updateInstituteFailed: boolean;

    pocLiveInstituteListLoading: boolean;
    pocLiveInstituteListLoaded: boolean;
    pocLiveInstituteListFailed: boolean;

    deleteInstituteLoading: boolean;
    deleteInstituteLoaded: boolean;
    deleteInstituteFailed: boolean;

    uploadInstituteLogoLoading: boolean;
    uploadInstituteLogoLoaded: boolean;
    uploadInstituteLogoFailed: boolean;

    instituteApprovalLoading: boolean;
    instituteApprovalLoaded: boolean;
    instituteApprovalFailed: boolean;

    instituteRenewalLoading: boolean;
    instituteRenewalLoaded: boolean;
    instituteRenewalFailed: boolean;

    uploadInstituteFileLoading: boolean;
    uploadInstituteFileLoaded: boolean;
    uploadInstituteFileFailed: boolean;

    standardListLoading: boolean;
    standardListLoaded: boolean;
    standardListFailed: boolean;

    sectionListLoading: boolean;
    sectionListLoaded: boolean;
    sectionListFailed: boolean;

    sectionStandardUpdateLoading: boolean;
    sectionStandardUpdateLoaded: boolean;
    sectionStandardUpdateFailed: boolean;

    getSectionStandardUpdateLoading: boolean;
    getSectionStandardUpdateLoaded: boolean;
    getSectionStandardUpdateFailed: boolean;
    getSectionStandardUpdateList: any;

    verifyInstituteLoading: boolean;
    verifyInstituteLoaded: boolean;
    verifyInstituteFailed: boolean;

    languageListLoading: boolean;
    languageListLoaded: boolean;
    languageListFailed: boolean;

    instituteListCountLoading: boolean;
    instituteListCountLoaded: boolean;
    instituteListCountFailed: boolean;

    GetScheduledInstituteDetails: any;
    GetScheduledInstituteDetailsLoading: boolean;

    getDashBoardCount:any;
    getDashBoardCountLoading:boolean;

}

export const InstituteManagementStateRecord = Record({
    createInstitute: [],
    instituteList: [],
    countryList: [],
    regionList: [],
    cityList: [],
    schoolTypeBranch: [],
    instituteSalesMan: [],
    instituteDetails: '',
    updateInstitute: [],
    pocLiveInstituteList: [],
    alllistCount: [],
    approvedCount: [],
    verificationPendingCount: [],
    approvalPendingcount: [],
    liveCount: [],
    pocCount: [],
    InactiveCount: [],
    isSelect: [],
    deleteInstitute: [],
    uploadInstituteLogo: [],
    uploadInstituteFile: [],
    standardList: [],
    sectionList: [],
    sectionStandardUpdate: [],
    verifyInstitute: [],
    additionalModuleList: [],
    languageList: [],
    inactiveCount: [],
    activeCount: [],
    approvalVerificationList: [],
    activeInactiveList: [],
    institutePaidCount: [],
    instituteCsrCount: [],
    smsProviderList: [],
    boardList: [],
    allInstituteList: [],
    instituteSearchCount: [],
    exportInstitute: [],
    redirectUrl: [],
    academicYearDetails: {},
    changeAcademicYearSetting: {},
    academicYearList: [],


    redirectUrlLoading: false,
    redirectUrlLoaded: false,
    redirectUrlFailed: false,

    exportInstituteLoading: false,
    exportInstituteLoaded: false,
    exportInstituteFailed: false,

    allInstituteListLoading: false,
    allInstituteListLoaded: false,
    allInstituteListFailed: false,


    boardListLoading: false,
    boardListLoaded: false,
    boardListFailed: false,

    smsProviderListLoading: false,
    smsProviderListLoaded: false,
    smsProviderListFailed: false,

    activeInactiveListLoading: false,
    activeInactiveListLoaded: false,
    activeInactiveListFailed: false,

    approvalVerificationListLoading: false,
    approvalVerificationListLoaded: false,
    approvalVerificationListFailed: false,

    additionalModuleListLoading: false,
    additionalModuleListLoaded: false,
    additionalModuleListFailed: false,

    createInstituteLoading: false,
    createInstituteLoaded: false,
    createInstituteFailed: false,

    instituteListLoading: false,
    instituteListLoaded: false,
    instituteListFailed: false,

    countryListLoading: false,
    countryListLoaded: false,
    countryListFailed: false,

    regionListLoading: false,
    regionListLoaded: false,
    regionListFailed: false,

    cityListLoading: false,
    cityListLoaded: false,
    cityListFailed: false,

    schoolTypeBranchLoading: false,
    schoolTypeBranchLoaded: false,
    schoolTypeBranchFailed: false,

    instituteSalesManLoading: false,
    instituteSalesManLoaded: false,
    instituteSalesManFailed: false,

    instituteDetailsLoading: false,
    instituteDetailsLoaded: false,
    instituteDetailsFailed: false,

    updateInstituteLoading: false,
    updateInstituteLoaded: false,
    updateInstituteFailed: false,

    pocLiveInstituteListLoading: false,
    pocLiveInstituteListLoaded: false,
    pocLiveInstituteListFailed: false,

    uploadInstituteLogoLoading: false,
    uploadInstituteLogoLoaded: false,
    uploadInstituteLogoFailed: false,

    instituteApprovalLoading: false,
    instituteApprovalLoaded: false,
    instituteApprovalFailed: false,

    instituteRenewalLoading: false,
    instituteRenewalLoaded: false,
    instituteRenewalFailed: false,

    uploadInstituteFileLoading: false,
    uploadInstituteFileLoaded: false,
    uploadInstituteFileFailed: false,

    standardListLoading: false,
    standardListLoaded: false,
    standardListFailed: false,

    sectionListLoading: false,
    sectionListLoaded: false,
    sectionListFailed: false,

    sectionStandardUpdateLoading: false,
    sectionStandardUpdateLoaded: false,
    sectionStandardUpdateFailed: false,

    getSectionStandardUpdateLoading: false,
    getSectionStandardUpdateLoaded: false,
    getSectionStandardUpdateFailed: false,
    getSectionStandardUpdateList: [],

    verifyInstituteLoading: false,
    verifyInstituteLoaded: false,
    verifyInstituteFailed: false,
    languageListLoading: false,
    languageListLoaded: false,
    languageListFailed: false,

    instituteListCountLoading: false,
    instituteListCountLoaded: false,
    instituteListCountFailed: false,

    GetScheduledInstituteDetails: {},
    GetScheduledInstituteDetailsLoading: true,

    getDashBoardCount:[],
    getDashBoardCountLoading:false,
})
    ;
