import * as actions from '../action/institute-management.action';
import { InstituteManagementState, InstituteManagementStateRecord } from './institute-management.state';
import { InstituteListResponse } from '../models/instituteList.model';
// Model

export const initialState: InstituteManagementState = new InstituteManagementStateRecord() as InstituteManagementState;
export function reducer(state = initialState, { type, payload }: any): InstituteManagementState {
    if (!type) {
        return state;
    }
    switch (type) {
        case actions.ActionTypes.CREATE_INSTITUTE: {
            return Object.assign({}, state, {
                createInstituteLoading: true,
                createInstituteLoaded: false,
                createInstituteFailed: false,
            });
        }
        case actions.ActionTypes.CREATE_INSTITUTE_SUCCESS: {
            return Object.assign({}, state, {
                createInstitute: payload,
                createInstituteLoading: false,
                createInstituteLoaded: true,
                createInstituteFailed: false,
            });
        }
        case actions.ActionTypes.CREATE_INSTITUTE_FAIL: {
            return Object.assign({}, state, {
                createInstituteLoading: false,
                createInstituteLoaded: false,
                createInstituteFailed: true,
            });
        }
        // get all Institute List
        case actions.ActionTypes.ALL_INSTITUTE_LIST: {
            return Object.assign({}, state, {
                allInstituteListLoading: true,
                allInstituteListLoaded: false,
                allInstituteListFailed: false,
            });
        }
        case actions.ActionTypes.ALL_INSTITUTE_LIST_SUCCESS: {
            return Object.assign({}, state, {
                allInstituteList: payload.data,
                allInstituteListLoading: false,
                allInstituteListLoaded: true,
                allInstituteListFailed: false,
            });
        }
        case actions.ActionTypes.ALL_INSTITUTE_LIST_FAIL: {
            return Object.assign({}, state, {
                allInstituteListLoading: false,
                allInstituteListLoaded: false,
                allInstituteListFailed: true,
            });
        }
        // sms provider
        case actions.ActionTypes.SMS_PROVIDER_LIST: {
            return Object.assign({}, state, {
                smsProviderListLoading: true,
                smsProviderListLoaded: false,
                smsProviderListFailed: false,
            });
        }
        case actions.ActionTypes.SMS_PROVIDER_LIST_SUCCESS: {
            return Object.assign({}, state, {
                smsProviderList: payload.data,
                smsProviderListLoading: false,
                smsProviderListLoaded: true,
                smsProviderListFailed: false,
            });
        }
        case actions.ActionTypes.SMS_PROVIDER_LIST_FAIL: {
            return Object.assign({}, state, {
                smsProviderListLoading: false,
                smsProviderListLoaded: false,
                smsProviderListFailed: true,
            });
        }
        // board List
        case actions.ActionTypes.BOARD_LIST: {
            return Object.assign({}, state, {
                boardListLoading: true,
                boardListLoaded: false,
                boardListFailed: false,
            });
        }
        case actions.ActionTypes.BOARD_LIST_SUCCESS: {
            return Object.assign({}, state, {
                boardList: payload.data,
                boardListLoading: false,
                boardListLoaded: true,
                boardListFailed: false,
            });
        }
        case actions.ActionTypes.BOARD_LIST_FAIL: {
            return Object.assign({}, state, {
                boardListLoading: false,
                boardListLoaded: false,
                boardListFailed: true,
            });

        }

        // INSTITUTE LIST

        case actions.ActionTypes.INSTITUTE_LIST: {
            return Object.assign({}, state, {
                pocLiveInstituteList: [],
                approvalVerificationList: [],
                activeInactiveList: [],
                // instituteList: [],
                instituteSearchCount: [],
                activeInactiveListLoading: false,
                pocLiveInstituteListLoading: false,
                approvalVerificationListLoading: false,
                instituteListLoading: true,
                instituteListLoaded: false,
                instituteListFailed: false,
            });


        }

        case actions.ActionTypes.INSTITUTE_LIST_SUCCESS: {
            console.log('payload.data', payload.data);

            const temp = [];
            temp['institute'] = true;
            var tempInstituteList;
            if(payload.data){
                tempInstituteList = payload.data.map(data => {
                    return new InstituteListResponse(data);
                });
                console.log('tempdata12', payload);
            }
           
            return Object.assign({}, state, {
                isSelect: temp,
                instituteSearchCount: payload.instituteSearchCount,
                instituteList: tempInstituteList,
                allListCount: payload.instituteCount,
                pocCount: payload.pocCount,
                liveCount: payload.liveCount,
                approvedCount: payload.approvedCount,
                approvalPendingCount: payload.approvalPendingCount,
                verificationPendingCount: payload.verificationPendingCount,
                inactiveCount: payload.inActiveInstitute,
                activeCount: payload.activeInstitute,
                institutePaidCount: payload.institutePaidCount,
                instituteCsrCount: payload.instituteCsrCount,
                instituteListLoading: false,
                instituteListLoaded: true,
                instituteListFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_LIST_FAIL: {
            return Object.assign({}, state, {
                instituteListLoading: false,
                instituteListLoaded: false,
                instituteListFailed: true,
            });
        }
        // institute verification Approval List
        case actions.ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST: {
            return Object.assign({}, state, {
                instituteList: [],
                instituteSearchCount: [],
                pocLiveInstituteList: [],
                activeInactiveList: [],
                // approvalVerificationList: [],
                activeInactiveListLoading: false,
                instituteListLoading: false,
                pocLiveInstituteListLoading: false,
                approvalVerificationListLoading: true,
                approvalVerificationListLoaded: true,
                approvalVerificationListFailed: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST_SUCCESS: {
            let tempInstituteApprovalList = [];
            if (payload.data.instituteList) {
                tempInstituteApprovalList = payload.data.instituteList.map(data => {
                    return new InstituteListResponse(data);
                });
            }
            return Object.assign({}, state, {
                instituteSearchCount: payload.data['instituteSearchCount'],
                approvalVerificationList: tempInstituteApprovalList,
                approvalVerificationListLoading: false,
                approvalVerificationListLoaded: true,
                approvalVerificationListFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST_FAIL: {
            return Object.assign({}, state, {
                approvalVerificationListLoading: false,
                approvalVerificationListLoaded: false,
                approvalVerificationListFailed: true,
            });
        }

        // pocLive List
        case actions.ActionTypes.INSTITUTE_POCLIVE_LIST: {
            return Object.assign({}, state, {
                instituteSearchCount: [],
                instituteList: [],
                approvalVerificationList: [],
                activeInactiveList: [],
                // pocLiveInstituteList: [],
                activeInactiveListLoading: false,
                instituteListLoading: false,
                approvalVerificationListLoading: false,
                pocLiveInstituteListLoading: true,
                pocLiveInstituteListLoaded: false,
                pocLiveInstituteListFailed: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_POCLIVE_LIST_SUCCESS: {
            const temp = [];
            temp['pocLive'] = true;
            const tempInstitutePocLiveList = payload.data.map(data => {
                return new InstituteListResponse(data);
            });
            console.log('INSTITUTE_POCLIVE_LIST', payload)
            return Object.assign({}, state, {
                instituteSearchCount: payload.instituteSearchCount,
                isSelect: temp,
                pocLiveInstituteList: tempInstitutePocLiveList,
                pocLiveInstituteListLoading: false,
                pocLiveInstituteListLoaded: true,
                pocLiveInstituteListFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_POCLIVE_LIST_FAIL: {
            return Object.assign({}, state, {
                pocLiveInstituteListLoading: false,
                pocLiveInstituteListLoaded: false,
                pocLiveInstituteListFailed: true,
            });
        }

        // active Inactive institute
        case actions.ActionTypes.ACTIVE_INACTIVE_LIST: {
            return Object.assign({}, state, {
                instituteSearchCount: [],
                instituteList: [],
                approvalVerificationList: [],
                pocLiveInstituteList: [],
                // activeInactiveList: [],
                instituteListLoading: false,
                approvalVerificationListLoading: false,
                pocLiveInstituteListLoading: false,
                activeInactiveListLoading: true,
                activeInactiveListLoaded: false,
                activeInactiveListFailed: false,
            });
        }

        case actions.ActionTypes.ACTIVE_INACTIVE_LIST_SUCCESS: {
            const tempActiveInactiveList = payload.data.instituteList.map(data => {
                return new InstituteListResponse(data);
            });
            console.log('ACTIVE_INACTIVE_LIST', payload)
            return Object.assign({}, state, {
                instituteSearchCount: payload.data['instituteSearchCount'],
                activeInactiveList: tempActiveInactiveList,
                activeInactiveListLoading: false,
                activeInactiveListLoaded: true,
                activeInactiveListFailed: false,
            });
        }


        case actions.ActionTypes.ACTIVE_INACTIVE_LIST_FAIL: {
            return Object.assign({}, state, {
                activeInactiveListLoading: false,
                activeInactiveListLoaded: false,
                activeInactiveListFailed: true,
            });
        }
        // Country LIST

        case actions.ActionTypes.COUNTRY_LIST: {
            return Object.assign({}, state, {
                countryListLoading: true,
                countryListLoaded: false,
                countryListFailed: false,
            });
        }

        case actions.ActionTypes.COUNTRY_LIST_SUCCESS: {
            return Object.assign({}, state, {
                countryList: payload.data,
                countryListLoading: false,
                countryListLoaded: true,
                countryListFailed: false,
            });
        }


        case actions.ActionTypes.COUNTRY_LIST_FAIL: {
            return Object.assign({}, state, {
                countryListLoading: false,
                countryListLoaded: false,
                countryListFailed: true,
            });
        }

        // Region LIST

        case actions.ActionTypes.REGION_LIST: {
            return Object.assign({}, state, {
                regionListLoading: true,
                regionListLoaded: false,
                regionListFailed: false,
            });
        }

        case actions.ActionTypes.REGION_LIST_SUCCESS: {
            return Object.assign({}, state, {
                regionList: payload.data,
                regionListLoading: true,
                regionListLoaded: true,
                regionListFailed: false,
            });
        }


        case actions.ActionTypes.REGION_LIST_FAIL: {
            return Object.assign({}, state, {
                regionListLoading: true,
                regionListLoaded: false,
                regionListFailed: true,
            });
        }

        // City LIST

        case actions.ActionTypes.CITY_LIST: {
            return Object.assign({}, state, {
                cityListLoading: true,
                cityListLoaded: false,
                cityListFailed: false,
            });
        }

        case actions.ActionTypes.CITY_LIST_SUCCESS: {
            return Object.assign({}, state, {
                cityList: payload.data,
                cityListLoading: false,
                cityListLoaded: true,
                cityListFailed: false,
            });
        }


        case actions.ActionTypes.CITY_LIST_FAIL: {
            return Object.assign({}, state, {
                cityListLoading: false,
                cityListLoaded: false,
                cityListFailed: true,
            });
        }
        // school type branch
        case actions.ActionTypes.SCHOOL_TYPE_BRANCH: {
            return Object.assign({}, state, {
                schoolTypeBranchLoading: true,
                schoolTypeBranchLoaded: false,
                schoolTypeBranchFailed: false,
            });
        }

        case actions.ActionTypes.SCHOOL_TYPE_BRANCH_SUCCESS: {
            return Object.assign({}, state, {
                schoolTypeBranch: payload.data,
                schoolTypeBranchLoading: false,
                schoolTypeBranchLoaded: true,
                schoolTypeBranchFailed: false,
            });
        }


        case actions.ActionTypes.SCHOOL_TYPE_BRANCH_FAIL: {
            return Object.assign({}, state, {
                schoolTypeBranchLoading: false,
                schoolTypeBranchLoaded: false,
                schoolTypeBranchFailed: true,
            });
        }
        // school sales man
        case actions.ActionTypes.INSTITUTE_SALESMAN: {
            return Object.assign({}, state, {
                instituteSalesManLoading: true,
                instituteSalesManLoaded: false,
                instituteSalesManFailed: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_SALESMAN_SUCCESS: {
            return Object.assign({}, state, {
                instituteSalesMan: payload.data,
                instituteSalesManLoading: false,
                instituteSalesManLoaded: true,
                instituteSalesManFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_SALESMAN_FAIL: {
            return Object.assign({}, state, {
                instituteSalesManLoading: false,
                instituteSalesManLoaded: false,
                instituteSalesManFailed: false,
            });
        }

        // institute details
        case actions.ActionTypes.INSTITUTE_DETAILS: {
            return Object.assign({}, state, {
                instituteDetails: '',
                instituteDetailsLoading: true,
                instituteDetailsLoaded: false,
                instituteDetailsFailed: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_DETAILS_SUCCESS: {
            let additionalModuleData: any = [];
            if (state.additionalModuleList) {
                additionalModuleData = state.additionalModuleList;
                if (payload.data.settingsData.length > 0) {
                    payload.data.settingsData.forEach(each => {
                        additionalModuleData = additionalModuleData.map(module => {
                            if (module.settingsName === each.settings_name) {
                                module.isChecked = true;
                                return module;
                            } else {
                                return module;
                            }
                        });
                    });
                }
            }
            console.log('pay', payload.data);
            return Object.assign({}, state, {
                additionalModuleList: additionalModuleData,
                instituteDetails: payload.data,
                instituteDetailsLoading: false,
                instituteDetailsLoaded: true,
                instituteDetailsFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_DETAILS_FAIL: {
            return Object.assign({}, state, {
                instituteDetails: '',
                iinstituteDetailsLoading: false,
                instituteDetailsLoaded: false,
                instituteDetailsFailed: true,
            });
        }

        // Update Institute
        case actions.ActionTypes.UPDATE_INSTITUTE: {
            return Object.assign({}, state, {
                instituteDetails: '',
                updateInstituteLoading: true,
                updateInstituteLoaded: false,
                updateInstituteFailed: false,
            });
        }

        case actions.ActionTypes.UPDATE_INSTITUTE_SUCCESS: {
            return Object.assign({}, state, {
                updateInstitute: payload.data,
                instituteDetails: '',
                updateInstituteLoading: false,
                updateInstituteLoaded: true,
                updateInstituteFailed: false,
            });
        }


        case actions.ActionTypes.UPDATE_INSTITUTE_FAIL: {
            return Object.assign({}, state, {
                updateInstituteLoading: false,
                updateInstituteLoaded: false,
                updateInstituteFailed: true,
            });
        }

        // empty institute details value variable
        case actions.ActionTypes.EMPTY_INSTITUTE_DETAILS_VARIABLE: {
            return Object.assign({}, state, {
                instituteDetails: '',
                additionalModuleList: undefined,
            });
        }

        // Delete Institute
        case actions.ActionTypes.DELETE_INSTITUTE: {
            return Object.assign({}, state, {
                deleteInstituteLoading: true,
                deleteInstituteLoaded: false,
                deleteInstituteFailed: false,
            });
        }

        case actions.ActionTypes.DELETE_INSTITUTE_SUCCESS: {
            return Object.assign({}, state, {
                deleteInstitute: payload,
                deleteInstituteLoading: false,
                deleteInstituteLoaded: true,
                deleteInstituteFailed: false,
            });
        }


        case actions.ActionTypes.DELETE_INSTITUTE_FAIL: {
            return Object.assign({}, state, {
                deleteInstituteLoading: false,
                deleteInstituteLoaded: false,
                deleteInstituteFailed: true,
            });
        }


        // empty delete institute variable value variable
        case actions.ActionTypes.EMPTY_DELETE_INSTITUTE_VARIABLE: {
            return Object.assign({}, state, {
                deleteInstitute: undefined,
            });
        }
        // upload institute logo
        case actions.ActionTypes.UPLOAD_INSTITUTE_LOGO: {
            return Object.assign({}, state, {
                uploadInstituteLogo: undefined,
                uploadInstituteLogoLoading: true,
                uploadInstituteLogoLoaded: false,
                uploadInstituteLogoFailed: false,
            });
        }

        case actions.ActionTypes.UPLOAD_INSTITUTE_LOGO_SUCCESS: {
            return Object.assign({}, state, {
                uploadInstituteLogo: payload,
                uploadInstituteLogoLoading: false,
                uploadInstituteLogoLoaded: true,
                uploadInstituteLogoFailed: false,
            });
        }


        case actions.ActionTypes.UPLOAD_INSTITUTE_LOGO_FAIL: {
            return Object.assign({}, state, {
                uploadInstituteLogoLoading: false,
                uploadInstituteLogoLoaded: false,
                uploadInstituteLogoFailed: true,
            });
        }

        // institute renewal
        case actions.ActionTypes.INSTITUTE_RENEWAL: {
            return Object.assign({}, state, {
                instituteRenewal: [],
                instituteDetails: '',
                instituteRenewalLoading: true,
                instituteRenewalLoaded: false,
                instituteRenewalFailed: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_RENEWAL_SUCCESS: {
            return Object.assign({}, state, {
                instituteDetails: '',
                instituteRenewal: payload,
                instituteRenewalLoading: false,
                instituteRenewalLoaded: true,
                instituteRenewalFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_RENEWAL_FAIL: {
            return Object.assign({}, state, {
                instituteRenewal: [],
                instituteRenewalLoading: false,
                instituteRenewalLoaded: false,
                instituteRenewalFailed: true,
            });
        }
        // institute approval
        case actions.ActionTypes.INSTITUTE_APPROVAL: {
            return Object.assign({}, state, {
                instituteDetails: '',
                instituteApproval: undefined,
                instituteApprovalLoading: true,
                instituteApprovalLoaded: false,
                instituteApprovalFailed: true,
            });
        }

        case actions.ActionTypes.INSTITUTE_APPROVAL_SUCCESS: {
            return Object.assign({}, state, {
                instituteDetails: '',
                instituteApproval: payload,
                instituteApprovalLoading: false,
                instituteApprovalLoaded: true,
                instituteApprovalFailed: false,
            });
        }


        case actions.ActionTypes.INSTITUTE_APPROVAL_FAIL: {
            return Object.assign({}, state, {
                instituteApproval: undefined,
                instituteApprovalLoading: false,
                instituteApprovalLoaded: false,
                instituteApprovalFailed: true,
            });
        }
        // upload institute file
        case actions.ActionTypes.UPLOAD_INSTITUTE_FILE: {
            return Object.assign({}, state, {
                uploadInstituteFile: undefined,
                uploadInstituteFileLoading: true,
                uploadInstituteFileLoaded: false,
                uploadInstituteFileFailed: false,
            });
        }

        case actions.ActionTypes.UPLOAD_INSTITUTE_FILE_SUCCESS: {
            return Object.assign({}, state, {
                uploadInstituteFile: payload,
                uploadInstituteFileLoading: false,
                uploadInstituteFileLoaded: true,
                uploadInstituteFileFailed: false,
            });
        }


        case actions.ActionTypes.UPLOAD_INSTITUTE_FILE_FAIL: {
            return Object.assign({}, state, {
                uploadInstituteFileLoading: false,
                uploadInstituteFileLoaded: false,
                uploadInstituteFileFailed: true,
            });
        }

        // empty upload institute file  variable
        case actions.ActionTypes.EMPTY_UPLOAD_INSTITUTE_FILE_VARIABLE: {
            return Object.assign({}, state, {
                uploadInstituteFile: undefined,
            });
        }

        // standard list
        case actions.ActionTypes.STANDARD_LIST: {
            return Object.assign({}, state, {
                standardListLoading: true,
                standardListLoaded: false,
                standardListFailed: false,
            });
        }

        case actions.ActionTypes.STANDARD_LIST_SUCCESS: {
            return Object.assign({}, state, {
                standardList: payload.data,
                standardListLoading: false,
                standardListLoaded: true,
                standardListFailed: false,
            });
        }


        case actions.ActionTypes.STANDARD_LIST_FAIL: {
            return Object.assign({}, state, {
                standardListLoading: false,
                standardListLoaded: false,
                standardListFailed: true,
            });
        }
        // section list
        case actions.ActionTypes.SECTION_LIST: {
            return Object.assign({}, state, {
                sectionListLoading: true,
                sectionListLoaded: false,
                sectionListFailed: false,
            });
        }

        case actions.ActionTypes.SECTION_LIST_SUCCESS: {
            return Object.assign({}, state, {
                sectionList: payload.data,
                sectionListLoading: false,
                sectionListLoaded: true,
                sectionListFailed: false,
            });
        }


        case actions.ActionTypes.SECTION_LIST_FAIL: {
            return Object.assign({}, state, {
                sectionListLoading: false,
                sectionListLoaded: false,
                sectionListFailed: true,
            });
        }
        // section standard update

        case actions.ActionTypes.SECTION_STANDARD_UPDATE: {
            return Object.assign({}, state, {
                sectionStandardUpdateLoading: true,
                sectionStandardUpdateLoaded: false,
                sectionStandardUpdateFailed: false,
            });
        }


        case actions.ActionTypes.SECTION_STANDARD_UPDATE_SUCCESS: {
            return Object.assign({}, state, {
                sectionStandardUpdate: payload.data,
                sectionStandardUpdateLoading: false,
                sectionStandardUpdateLoaded: true,
                sectionStandardUpdateFailed: false,
            });
        }

        case actions.ActionTypes.SECTION_STANDARD_UPDATE_FAIL: {
            return Object.assign({}, state, {
                sectionStandardUpdateLoading: false,
                sectionStandardUpdateLoaded: false,
                sectionStandardUpdateFailed: true,
            });
        }

        // section standard update

        case actions.ActionTypes.GET_SECTION_STANDARD_UPDATE: {
            return Object.assign({}, state, {
                getSectionStandardUpdateLoading: true,
                getSectionStandardUpdateLoaded: false,
                getSectionStandardUpdateFailed: false,
                getSectionStandardUpdateList: [],
            });
        }


        case actions.ActionTypes.GET_SECTION_STANDARD_UPDATE_SUCCESS: {
            const selectedStandard = [];
            const selectedStandardSections = {};
            const groupBy = key => array =>
                array.reduce((objectsByKeyValue, obj) => {
                    const value = obj[key];
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                    return objectsByKeyValue;
                }, {});

            const groupByStandardId = groupBy('standardId');
            const groupBySectionName = groupBy('sectionName');
            const standardInputArray = groupByStandardId(state.standardList);
            const sectionInputArray = groupBySectionName(state.sectionList);
            payload.data.forEach(element => {
                if (standardInputArray[element.standardId] && standardInputArray[element.standardId].length > 0) {
                    selectedStandard.push(standardInputArray[element.standardId][0]);
                    selectedStandardSections[element.standardId] = [];
                }
                element.sectionDetails.forEach(section => {
                    if (sectionInputArray[section.sectionName] && sectionInputArray[section.sectionName].length > 0) {
                        selectedStandardSections[element.standardId].push(sectionInputArray[section.sectionName][0]);
                    }
                });
                selectedStandardSections[element.standardId].sort((a, b) => parseFloat(a.sectionLevel) - parseFloat(b.sectionLevel));
            });
            selectedStandard.sort((a, b) => parseFloat(a.standardLevel) - parseFloat(b.standardLevel));
            return Object.assign({}, state, {
                getSectionStandardUpdateLoading: false,
                getSectionStandardUpdateLoaded: true,
                getSectionStandardUpdateFailed: false,
                getSectionStandardUpdateList: {
                    selectedStandard: selectedStandard,
                    selectedStandardSections: selectedStandardSections,
                },
            });
        }

        case actions.ActionTypes.GET_SECTION_STANDARD_UPDATE_FAIL: {
            return Object.assign({}, state, {
                sectionStandardUpdateLoading: false,
                sectionStandardUpdateLoaded: false,
                sectionStandardUpdateFailed: true,
            });
        }
        // clear institute List INSTITUTE_LIST_CLEAR
        case actions.ActionTypes.INSTITUTE_LIST_CLEAR: {
            return Object.assign({}, state, {
                // instituteList: undefined,
                // pocLiveInstituteList: undefined,
            });
        }
        // verify institute
        case actions.ActionTypes.VERIFY_INSTITUTE: {
            return Object.assign({}, state, {
                verifyInstituteLoading: true,
                verifyInstituteLoaded: false,
                verifyInstituteFailed: false,
            });
        }

        case actions.ActionTypes.VERIFY_INSTITUTE_SUCCESS: {
            return Object.assign({}, state, {
                verifyInstitute: payload.data,
                verifyInstituteLoading: false,
                verifyInstituteLoaded: true,
                verifyInstituteFailed: false,
            });
        }


        case actions.ActionTypes.VERIFY_INSTITUTE_FAIL: {
            return Object.assign({}, state, {
                verifyInstituteLoading: false,
                verifyInstituteLoaded: false,
                verifyInstituteFailed: true,
            });
        }

        // section standard update

        case actions.ActionTypes.ADDITIONAL_MODULE_LIST: {
            return Object.assign({}, state, {
                additionalModuleListLoading: true,
                additionalModuleListLoaded: false,
                additionalModuleListFailed: false,
            });
        }


        case actions.ActionTypes.ADDITIONAL_MODULE_LIST_SUCCESS: {
            const moduleList = payload.data.map(data => {
                data['isChecked'] = false;
                return data;
            });
            return Object.assign({}, state, {
                additionalModuleList: moduleList,
                additionalModuleListLoading: false,
                additionalModuleListLoaded: true,
                additionalModuleListFailed: false,
            });
        }

        case actions.ActionTypes.ADDITIONAL_MODULE_LIST_FAIL: {
            return Object.assign({}, state, {
                additionalModuleListLoading: false,
                additionalModuleListLoaded: false,
                additionalModuleListFailed: true,
            });
        }
        // additional checkedADDITIONAL_CHECKED
        case actions.ActionTypes.ADDITIONAL_CHECKED: {
            const additionalModuleData: any = state.additionalModuleList;
            if (state.additionalModuleList) {
                additionalModuleData.forEach(data => {
                    if (payload === true) {
                        data['isChecked'] = true;
                        return data;
                    } else {
                        data['isChecked'] = false;
                        return data;
                    }
                });
            }
            return Object.assign({}, state, {
                additionalModuleList: additionalModuleData,
                additionalModuleListLoading: false,
                additionalModuleListLoaded: true,
                additionalModuleListFailed: false,
            });
        }
        // language LIST

        case actions.ActionTypes.LANGUAGE_LIST: {
            return Object.assign({}, state, {
                languageList: [],
                languageListLoading: true,
                languageListLoaded: false,
                languageListFailed: false,
            });
        }
        case actions.ActionTypes.LANGUAGE_LIST_SUCCESS: {
            return Object.assign({}, state, {
                languageList: payload.data,
                languageListLoading: false,
                languageListLoaded: true,
                languageListFailed: false,
            });
        }
        case actions.ActionTypes.LANGUAGE_LIST_FAIL: {
            return Object.assign({}, state, {
                languageListLoading: false,
                languageListLoaded: false,
                languageListFailed: true,
            });
        }
        // clear get Institute Data
        case actions.ActionTypes.CLEAR_INSTITUTE_DATA: {
            return Object.assign({}, state, {
                instituteDetails: '',
            });
        }

        // get all Institute List count
        case actions.ActionTypes.INSTITUTE_LIST_COUNT: {
            return Object.assign({}, state, {
                instituteListCountLoading: true,
                instituteListCountLoaded: false,
                instituteListCountFailed: false,
            });
        }
        case actions.ActionTypes.INSTITUTE_LIST_COUNT_SUCCESS: {
            console.log('INSTITUTE_LIST_COUNT', payload)
            return Object.assign({}, state, {
                allListCount: payload.instituteCount,
                pocCount: payload.pocCount,
                liveCount: payload.liveCount,
                approvedCount: payload.approvedCount,
                approvalPendingCount: payload.approvalPendingCount,
                verificationPendingCount: payload.verificationPendingCount,
                inactiveCount: payload.inActiveInstitute,
                activeCount: payload.activeInstitute,
                institutePaidCount: payload.institutePaidCount,
                instituteCsrCount: payload.instituteCsrCount,
                instituteListCountLoading: false,
                instituteListCountLoaded: true,
                instituteListCountFailed: false,
                // instituteSearchCount: payload.data['instituteSearchCount'],
            });
        }
        case actions.ActionTypes.INSTITUTE_LIST_COUNT_FAIL: {
            return Object.assign({}, state, {
                instituteListCountLoading: false,
                instituteListCountLoaded: false,
                instituteListCountFailed: true,
            });
        }
        // institute export
        case actions.ActionTypes.EXPORT_INSTITUTE_LIST: {
            return Object.assign({}, state, {
                exportInstitute: [],
                exportInstituteLoading: true,
                exportInstituteLoaded: false,
                exportInstituteFailed: false,
            });
        }
        case actions.ActionTypes.EXPORT_INSTITUTE_LIST_SUCCESS: {
            return Object.assign({}, state, {
                exportInstitute: payload.data,
                exportInstituteLoading: false,
                exportInstituteLoaded: true,
                exportInstituteFailed: false,
            });
        }
        case actions.ActionTypes.EXPORT_INSTITUTE_LIST_FAIL: {
            return Object.assign({}, state, {
                exportInstituteLoading: false,
                exportInstituteLoaded: false,
                exportInstituteFailed: true,
            });
        }

        case actions.ActionTypes.REDIRECT_TO_INSTITUTE: {
            return Object.assign({}, state, {
                redirectUrl: [],
                redirectUrlLoading: true,
                redirectUrlLoaded: false,
                redirectUrlFailed: false,
            });
        }
        case actions.ActionTypes.REDIRECT_TO_INSTITUTE_SUCCESS: {
            return Object.assign({}, state, {
                redirectUrl: payload,
                redirectUrlLoading: false,
                redirectUrlLoaded: true,
                redirectUrlFailed: false,
            });
        }
        case actions.ActionTypes.REDIRECT_TO_INSTITUTE_FAIL: {
            return Object.assign({}, state, {
                redirectUrl: [],
                redirectUrlLoading: false,
                redirectUrlLoaded: false,
                redirectUrlFailed: true,
            });
        }
        //
        case actions.ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS: {
            return Object.assign({}, state, {
                instituteDetails: '',
                instituteDetailsLoading: true,
                instituteDetailsLoaded: false,
                instituteDetailsFailed: false,
            });
        }

        case actions.ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS_SUCCESS: {
            let additionalModuleData: any = [];
            if (state.additionalModuleList) {
                additionalModuleData = state.additionalModuleList;
                if (payload.data.settingsData.length > 0) {
                    payload.data.settingsData.forEach(each => {
                        additionalModuleData = additionalModuleData.map(module => {
                            if (module.settingsName === each.settings_name) {
                                module.isChecked = true;
                                return module;
                            } else {
                                return module;
                            }
                        });
                    });
                }
            }
            console.log('pay', payload);
            return Object.assign({}, state, {
                additionalModuleList: additionalModuleData,
                instituteDetails: payload.data,
                instituteDetailsLoading: false,
                instituteDetailsLoaded: true,
                instituteDetailsFailed: false,
            });
        }


        case actions.ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS_FAIL: {
            return Object.assign({}, state, {
                instituteDetails: '',
                iinstituteDetailsLoading: false,
                instituteDetailsLoaded: false,
                instituteDetailsFailed: true,
            });
        }

        //
        case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION:
            return Object.assign({}, state, {});

        case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS:
            return Object.assign({}, state, {
                academicYearDetails: payload.data
            });

        case actions.ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING:
            return Object.assign({}, state, {});

        case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS:
            return Object.assign({}, state, {
                changeAcademicYearSetting: payload
            });

        case actions.ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_FAIL:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION:
            return Object.assign({}, state, {});

        case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION_SUCCESS:
            return Object.assign({}, state, {
                academicYearList: payload.data
            });

        case actions.ActionTypes.ACADEMIC_YEAR_LIST_ACTION_FAIL:
            return Object.assign({}, state, {
                academicYearList: []
            });


            case actions.ActionTypes.GET_DASHBOARD_COUNT:
                return Object.assign({}, state, {});
    
            case actions.ActionTypes.GET_DASHBOARD_COUNT_SUCCESS:
                return Object.assign({}, state, {
                    getDashBoardCount: payload.data
                });
    
            case actions.ActionTypes.GET_DASHBOARD_COUNT_FAIL:
                return Object.assign({}, state, {
                    getDashBoardCount: []
                });

            
        default: {
            return state;
        }
    }
}
export const createInstitute = (state: InstituteManagementState) => state.createInstitute;
export const createInstituteLoading = (state: InstituteManagementState) => state.createInstituteLoading;
export const createInstituteLoaded = (state: InstituteManagementState) => state.createInstituteLoaded;
export const createInstituteFailed = (state: InstituteManagementState) => state.createInstituteFailed;

export const instituteList = (state: InstituteManagementState) => state.instituteList;
export const instituteListLoading = (state: InstituteManagementState) => state.instituteListLoading;
export const instituteListLoaded = (state: InstituteManagementState) => state.instituteListLoaded;
export const instituteListFailed = (state: InstituteManagementState) => state.instituteListFailed;

// country list
export const countryList = (state: InstituteManagementState) => state.countryList;
export const countryListLoading = (state: InstituteManagementState) => state.countryListLoading;
export const countryListLoaded = (state: InstituteManagementState) => state.countryListLoaded;
export const countryListFailed = (state: InstituteManagementState) => state.countryListFailed;
// region list

export const regionList = (state: InstituteManagementState) => state.regionList;
export const regionListLoading = (state: InstituteManagementState) => state.regionListLoading;
export const regionListLoaded = (state: InstituteManagementState) => state.regionListLoaded;
export const regionListFailed = (state: InstituteManagementState) => state.regionListFailed;
// city list

export const cityList = (state: InstituteManagementState) => state.cityList;
export const cityListLoading = (state: InstituteManagementState) => state.cityListLoading;
export const cityListLoaded = (state: InstituteManagementState) => state.cityListLoaded;
export const cityListFailed = (state: InstituteManagementState) => state.cityListFailed;

// school type

export const schoolTypeBranch = (state: InstituteManagementState) => state.schoolTypeBranch;
export const schoolTypeBranchLoading = (state: InstituteManagementState) => state.schoolTypeBranchLoading;
export const schoolTypeBranchLoaded = (state: InstituteManagementState) => state.schoolTypeBranchLoaded;
export const schoolTypeBranchFailed = (state: InstituteManagementState) => state.schoolTypeBranchFailed;

// school sales Man

export const instituteSalesMan = (state: InstituteManagementState) => state.instituteSalesMan;
export const instituteSalesManLoading = (state: InstituteManagementState) => state.instituteSalesManLoading;
export const instituteSalesManLoaded = (state: InstituteManagementState) => state.instituteSalesManLoaded;
export const instituteSalesManFailed = (state: InstituteManagementState) => state.instituteSalesManFailed;

// get institute detail
export const instituteDetail = (state: InstituteManagementState) => state.instituteDetails;
export const instituteDetailLoading = (state: InstituteManagementState) => state.instituteDetailsLoading;
export const instituteDetailLoaded = (state: InstituteManagementState) => state.updateInstituteLoaded;
export const instituteDetailFailed = (state: InstituteManagementState) => state.instituteDetailsFailed;

// get update
export const updateInstitute = (state: InstituteManagementState) => state.updateInstitute;
export const updateInstituteLoading = (state: InstituteManagementState) => state.updateInstituteLoading;
export const updateInstituteLoaded = (state: InstituteManagementState) => state.updateInstituteLoaded;
export const updateInstituteFailed = (state: InstituteManagementState) => state.updateInstituteFailed;
// get poc list
export const pocLiveInstituteList = (state: InstituteManagementState) => state.pocLiveInstituteList;
export const pocLiveInstituteListLoading = (state: InstituteManagementState) => state.pocLiveInstituteListLoading;
export const pocLiveInstituteListLoaded = (state: InstituteManagementState) => state.pocLiveInstituteListLoaded;
export const pocLiveInstituteListFailed = (state: InstituteManagementState) => state.pocLiveInstituteListFailed;
// temp data for show no row
export const isSelect = (state: InstituteManagementState) => state.isSelect;
// getting all services Count
export const instituteCount = (state: InstituteManagementState) => state.allListCount;
export const pocCount = (state: InstituteManagementState) => state.pocCount;
export const liveCount = (state: InstituteManagementState) => state.liveCount;

// get delete
export const deleteInstitute = (state: InstituteManagementState) => state.deleteInstitute;
export const deleteInstituteLoading = (state: InstituteManagementState) => state.deleteInstituteLoading;
export const deleteInstituteLoaded = (state: InstituteManagementState) => state.deleteInstituteLoaded;
export const deleteInstituteFailed = (state: InstituteManagementState) => state.deleteInstituteFailed;
// upload institute logo
export const uploadInstituteLogo = (state: InstituteManagementState) => state.uploadInstituteLogo;
export const uploadInstituteLogoLoading = (state: InstituteManagementState) => state.uploadInstituteLogoLoading;
export const uploadInstituteLogoLoaded = (state: InstituteManagementState) => state.uploadInstituteLogoLoaded;
export const uploadInstituteLogoFailed = (state: InstituteManagementState) => state.uploadInstituteLogoFailed;
// institute renewal

export const instituteRenewal = (state: InstituteManagementState) => state.instituteRenewal;
export const instituteRenewalLoading = (state: InstituteManagementState) => state.instituteRenewalLoading;
export const instituteRenewalLoaded = (state: InstituteManagementState) => state.instituteRenewalLoaded;
export const instituteRenewalFailed = (state: InstituteManagementState) => state.instituteRenewalFailed;
// institute approval

export const instituteApproval = (state: InstituteManagementState) => state.instituteApproval;
export const instituteApprovalLoading = (state: InstituteManagementState) => state.instituteApprovalLoading;
export const instituteApprovalLoaded = (state: InstituteManagementState) => state.instituteApprovalLoaded;
export const instituteApprovalFailed = (state: InstituteManagementState) => state.instituteApprovalFailed;

// upload institute file
export const uploadInstituteFile = (state: InstituteManagementState) => state.uploadInstituteFile;
export const uploadInstituteFileLoading = (state: InstituteManagementState) => state.uploadInstituteFileLoading;
export const uploadInstituteFileLoaded = (state: InstituteManagementState) => state.uploadInstituteFileLoaded;
export const uploadInstituteFileFailed = (state: InstituteManagementState) => state.uploadInstituteFileFailed;

// standard list
export const standardList = (state: InstituteManagementState) => state.standardList;
export const standardListLoading = (state: InstituteManagementState) => state.standardListLoading;
export const standardListLoaded = (state: InstituteManagementState) => state.standardListLoaded;
export const standardListFailed = (state: InstituteManagementState) => state.standardListFailed;

// section list
export const sectionList = (state: InstituteManagementState) => state.sectionList;
export const sectionListLoading = (state: InstituteManagementState) => state.sectionListLoading;
export const sectionListLoaded = (state: InstituteManagementState) => state.sectionListLoaded;
export const sectionListFailed = (state: InstituteManagementState) => state.sectionListFailed;

// section standard update
export const sectionStandardUpdate = (state: InstituteManagementState) => state.sectionStandardUpdate;
export const sectionStandardUpdateLoading = (state: InstituteManagementState) => state.sectionStandardUpdateLoading;
export const sectionStandardUpdateLoaded = (state: InstituteManagementState) => state.sectionStandardUpdateLoaded;
export const sectionStandardUpdateFailed = (state: InstituteManagementState) => state.sectionStandardUpdateFailed;


// get section standard update
export const getSectionStandardUpdate = (state: InstituteManagementState) => state.getSectionStandardUpdateList;
export const getSectionStandardUpdateLoading = (state: InstituteManagementState) => state.getSectionStandardUpdateLoading;
export const getSectionStandardUpdateLoaded = (state: InstituteManagementState) => state.getSectionStandardUpdateLoaded;
export const getSectionStandardUpdateFailed = (state: InstituteManagementState) => state.getSectionStandardUpdateFailed;

// verify institute
export const verifyInstitute = (state: InstituteManagementState) => state.verifyInstitute;
export const verifyInstituteLoading = (state: InstituteManagementState) => state.verifyInstituteLoading;
export const verifyInstituteLoaded = (state: InstituteManagementState) => state.verifyInstituteLoaded;
export const verifyInstituteFailed = (state: InstituteManagementState) => state.verifyInstituteFailed;
// Additional module
export const additionalModuleList = (state: InstituteManagementState) => state.additionalModuleList;
export const additionalModuleListLoading = (state: InstituteManagementState) => state.additionalModuleListLoading;
export const additionalModuleListLoaded = (state: InstituteManagementState) => state.additionalModuleListLoaded;
export const additionalModuleListFailed = (state: InstituteManagementState) => state.additionalModuleListFailed;

// get language list
export const languageList = (state: InstituteManagementState) => state.languageList;
export const languageListLoading = (state: InstituteManagementState) => state.languageListLoading;
export const languageListLoaded = (state: InstituteManagementState) => state.languageListLoaded;
export const languageListFailed = (state: InstituteManagementState) => state.languageListFailed;

// institute count
export const approvedCount = (state: InstituteManagementState) => state.approvedCount;
export const approvalPendingCount = (state: InstituteManagementState) => state.approvalPendingCount;
export const verificationPendingCount = (state: InstituteManagementState) => state.verificationPendingCount;
export const inactiveCount = (state: InstituteManagementState) => state.inactiveCount;
export const activeCount = (state: InstituteManagementState) => state.activeCount;
export const institutePaidCount = (state: InstituteManagementState) => state.institutePaidCount;
export const instituteCsrCount = (state: InstituteManagementState) => state.instituteCsrCount;

// institute verification Approval
export const approvalVerificationList = (state: InstituteManagementState) => state.approvalVerificationList;
export const approvalVerificationListLoading = (state: InstituteManagementState) => state.approvalVerificationListLoading;
export const approvalVerificationListLoaded = (state: InstituteManagementState) => state.approvalVerificationListLoaded;
export const approvalVerificationListFailed = (state: InstituteManagementState) => state.approvalVerificationListFailed;

// institute active inactive list
export const activeInactiveList = (state: InstituteManagementState) => state.activeInactiveList;
export const activeInactiveListLoading = (state: InstituteManagementState) => state.activeInactiveListLoading;
export const activeInactiveListLoaded = (state: InstituteManagementState) => state.activeInactiveListLoaded;
export const activeInactiveListFailed = (state: InstituteManagementState) => state.activeInactiveListFailed;
// service provider List
export const smsProviderList = (state: InstituteManagementState) => state.smsProviderList;
export const smsProviderListLoading = (state: InstituteManagementState) => state.smsProviderListLoading;
export const smsProviderListLoaded = (state: InstituteManagementState) => state.smsProviderListLoaded;
export const smsProviderListFailed = (state: InstituteManagementState) => state.smsProviderListFailed;
// board list
export const boardList = (state: InstituteManagementState) => state.boardList;
export const boardListLoading = (state: InstituteManagementState) => state.boardListLoading;
export const boardListLoaded = (state: InstituteManagementState) => state.boardListLoaded;
export const boardListFailed = (state: InstituteManagementState) => state.boardListFailed;
// all institute list
export const allInstituteList = (state: InstituteManagementState) => state.allInstituteList;
export const allInstituteListLoading = (state: InstituteManagementState) => state.allInstituteListLoading;
export const allInstituteListLoaded = (state: InstituteManagementState) => state.allInstituteListLoaded;
export const allInstituteListFailed = (state: InstituteManagementState) => state.allInstituteListFailed;
// all institute list
export const exportInstitute = (state: InstituteManagementState) => state.exportInstitute;
export const exportInstituteLoading = (state: InstituteManagementState) => state.exportInstituteLoading;
export const exportInstituteLoaded = (state: InstituteManagementState) => state.exportInstituteLoaded;
export const exportInstituteFailed = (state: InstituteManagementState) => state.exportInstituteFailed;
// institute search count
export const instituteSearchCount = (state: InstituteManagementState) => state.instituteSearchCount;
// redirct url
export const redirectUrl = (state: InstituteManagementState) => state.redirectUrl;
export const redirectUrlLoading = (state: InstituteManagementState) => state.redirectUrlLoading;
export const redirectUrlLoaded = (state: InstituteManagementState) => state.redirectUrlLoaded;
//
export const changeAcademicYearSetting = (state: InstituteManagementState) => state.changeAcademicYearSetting;
export const academicYearDetails = (state: InstituteManagementState) => state.academicYearDetails;
export const academicYearList = (state: InstituteManagementState) => state.academicYearList;

export const GetScheduledInstituteDetails = (state: InstituteManagementState) => state.GetScheduledInstituteDetails;
export const GetScheduledInstituteDetailsLoading = (state: InstituteManagementState) => state.GetScheduledInstituteDetailsLoading;

export const getDashBoardCount = (state: InstituteManagementState) => state.getDashBoardCount;
export const getDashBoardCountLoading = (state: InstituteManagementState) => state.getDashBoardCountLoading;

