// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { redirectUrl } from "src/app/pages/institute-mangements/reducer/institute-management.reducer";

export const environment = {
  production: false,
  // baseUrl:  'http://13.235.173.26/backend/api',
  baseUrl: 'https://vs5.voicesnapforschools.com/nodejs/api',
  redirectUrl:'http://www.academyx.in#/auto-login',
  // redirectUrl:'http://localhost:4200#/auto-login',

 

  // baseUrl:  'http://vstest3.voicesnapforschools.com/nodejs/api',
  // baseUrl: 'http://localhost:8009/api',
  // baseUrl:  'http://202.21.35.54/nodejs/api',
  imageUrl: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
