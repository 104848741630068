import { Action } from '@ngrx/store';
import { ResponsInterface } from '../../../response-model/response-interface';
import { type } from '../../../shared/utility/utilityHelpers';
import { InstituteListModel } from '../models/institute-list.model';
import { UploadInstituteLogoModel } from '../models/upload-institute-logo.model';
import { InstituteRenewalModel } from '../models/institute.renewal.model';
import { InstituteApprovalModel } from '../models/institute.approval.model';
import { UploadInstituteFileModel } from '../models/upload-institute-file.model';

export const ActionTypes = {
    // create ACTIONTYPES
    CREATE_INSTITUTE: type('[INSTITUTE] CREATE_INSTITUTE'),
    CREATE_INSTITUTE_SUCCESS: type('[INSTITUTE] CREATE_INSTITUTE_SUCCESS'),
    CREATE_INSTITUTE_FAIL: type('[INSTITUTE] CREATE_INSTITUTE_FAIL'),

    // INSTITUTE LIST
    INSTITUTE_LIST: type('[INSTITUTE_LIST] INSTITUTE_LIST'),
    INSTITUTE_LIST_SUCCESS: type('[INSTITUTE_LIST] INSTITUTE_LIST SUCCESS'),
    INSTITUTE_LIST_FAIL: type('[INSTITUTE_LIST] INSTITUTE_LIST FAIL'),

    // getting region list
    REGION_LIST: type('[REGION_LIST] REGION_LIST'),
    REGION_LIST_SUCCESS: type('[REGION_LIST] REGION_LIST SUCCESS'),
    REGION_LIST_FAIL: type('[REGION_LIST] REGION_LIST FAIL'),

    // getting country list
    COUNTRY_LIST: type('[COUNTRY_LIST] COUNTRY_LIST'),
    COUNTRY_LIST_SUCCESS: type('[COUNTRY_LIST] COUNTRY_LIST SUCCESS'),
    COUNTRY_LIST_FAIL: type('[COUNTRY_LIST] COUNTRY_LIST FAIL'),

    // getting city List
    CITY_LIST: type('[CITY_LIST] CITY_LIST'),
    CITY_LIST_SUCCESS: type('[CITY_LIST] CITY_LIST SUCCESS'),
    CITY_LIST_FAIL: type('[CITY_LIST] CITY_LIST FAIL'),

    // calling School type Branch
    SCHOOL_TYPE_BRANCH: type('[SCHOOL_TYPE_BRANCH] SCHOOL_TYPE_BRANCH'),
    SCHOOL_TYPE_BRANCH_SUCCESS: type('[SCHOOL_TYPE_BRANCH] SCHOOL_TYPE_BRANCH SUCCESS'),
    SCHOOL_TYPE_BRANCH_FAIL: type('[SCHOOL_TYPE_BRANCH] SCHOOL_TYPE_BRANCH FAIL'),

    // getting institute details Action

    INSTITUTE_DETAILS: type('[INSTITUTE] INSTITUTE_DETAILS'),
    INSTITUTE_DETAILS_SUCCESS: type('[INSTITUTE] INSTITUTE_DETAILS_SUCCESS'),
    INSTITUTE_DETAILS_FAIL: type('[INSTITUTE] INSTITUTE_DETAILS_FAIL'),
    // getting institute update Action

    UPDATE_INSTITUTE: type('[INSTITUTE] UPDATE_INSTITUTE'),
    UPDATE_INSTITUTE_SUCCESS: type('[INSTITUTE] UPDATE_INSTITUTE_SUCCESS'),
    UPDATE_INSTITUTE_FAIL: type('[INSTITUTE] UPDATE_INSTITUTE_FAIL'),
    // calling sales man Action

    INSTITUTE_SALESMAN: type('[INSTITUTE] INSTITUTE_SALESMAN'),
    INSTITUTE_SALESMAN_SUCCESS: type('[INSTITUTE] INSTITUTE_SALESMAN_SUCCESS'),
    INSTITUTE_SALESMAN_FAIL: type('[INSTITUTE] INSTITUTE_SALESMAN_FAIL'),
    // calling poclive list
    INSTITUTE_POCLIVE_LIST: type('[INSTITUTE] INSTITUTE_POCLIVE_LIST'),
    INSTITUTE_POCLIVE_LIST_SUCCESS: type('[INSTITUTE] INSTITUTE_POCLIVE_LIST_SUCCESS'),
    INSTITUTE_POCLIVE_LIST_FAIL: type('[INSTITUTE] INSTITUTE_POCLIVE_LIST_FAIL'),

    // empty institute details data in reducer
    EMPTY_INSTITUTE_DETAILS_VARIABLE: type('[INSTITUTE] EMPTY_INSTITUTE_DETAILS_VAR'),

    // getting institute delete Action
    DELETE_INSTITUTE: type('[INSTITUTE] DELETE_INSTITUTE'),
    DELETE_INSTITUTE_SUCCESS: type('[INSTITUTE] DELETE_INSTITUTE_SUCCESS'),
    DELETE_INSTITUTE_FAIL: type('[INSTITUTE] DELETE_INSTITUTE_FAIL'),

    // empty delete institute variable in reducer
    EMPTY_DELETE_INSTITUTE_VARIABLE: type('[INSTITUTE] EMPTY_DELETE_INSTITUTE_VARIABLE'),
    // upload institute logo
    UPLOAD_INSTITUTE_LOGO: type('[INSTITUTE] UPLOAD_INSTITUTE_LOGO'),
    UPLOAD_INSTITUTE_LOGO_SUCCESS: type('[INSTITUTE] UPLOAD_INSTITUTE_LOGO_SUCCESS'),
    UPLOAD_INSTITUTE_LOGO_FAIL: type('[INSTITUTE] UPLOAD_INSTITUTE_LOGO_FAIL'),

    // institute approval action
    INSTITUTE_APPROVAL: type('[INSTITUTE] INSTITUTE_APPROVAL'),
    INSTITUTE_APPROVAL_SUCCESS: type('[INSTITUTE] INSTITUTE_APPROVAL_SUCCESS'),
    INSTITUTE_APPROVAL_FAIL: type('[INSTITUTE] INSTITUTE_APPROVAL_FAIL'),

    // institute renewal action
    INSTITUTE_RENEWAL: type('[INSTITUTE] INSTITUTE_RENEWAL'),
    INSTITUTE_RENEWAL_SUCCESS: type('[INSTITUTE] INSTITUTE_RENEWAL_SUCCESS'),
    INSTITUTE_RENEWAL_FAIL: type('[INSTITUTE] INSTITUTE_RENEWAL_FAIL'),

    // upload institute file
    UPLOAD_INSTITUTE_FILE: type('[INSTITUTE] UPLOAD_INSTITUTE_FILE'),
    UPLOAD_INSTITUTE_FILE_SUCCESS: type('[INSTITUTE] UPLOAD_INSTITUTE_FILE_SUCCESS'),
    UPLOAD_INSTITUTE_FILE_FAIL: type('[INSTITUTE] UPLOAD_INSTITUTE_FILE_FAIL'),

    // empty institute details data in reducer
    EMPTY_UPLOAD_INSTITUTE_FILE_VARIABLE: type('[INSTITUTE] EMPTY_UPLOAD_INSTITUTE_FILE_VARIABLE'),

    // getting standard list
    STANDARD_LIST: type('[INSTITUTE] STANDARD_LIST'),
    STANDARD_LIST_SUCCESS: type('[INSTITUTE] STANDARD_LIST_SUCCESS'),
    STANDARD_LIST_FAIL: type('[INSTITUTE] STANDARD_LIST_FAIL'),

    // getting section list
    SECTION_LIST: type('[INSTITUTE] SECTION_LIST'),
    SECTION_LIST_SUCCESS: type('[INSTITUTE] SECTION_LIST_SUCCESS'),
    SECTION_LIST_FAIL: type('[INSTITUTE] SECTION_LIST_FAIL'),

    // update standard and section list
    SECTION_STANDARD_UPDATE: type('[INSTITUTE] SECTION_STANDARD_UPDATE'),
    SECTION_STANDARD_UPDATE_SUCCESS: type('[INSTITUTE] SECTION_STANDARD_UPDATE_SUCCESS'),
    SECTION_STANDARD_UPDATE_FAIL: type('[INSTITUTE] SECTION_STANDARD_UPDATE_FAIL'),

    // update standard and section list
    GET_SECTION_STANDARD_UPDATE: type('[INSTITUTE] GET SECTION_STANDARD_UPDATE'),
    GET_SECTION_STANDARD_UPDATE_SUCCESS: type('[INSTITUTE] GET SECTION_STANDARD_UPDATE_SUCCESS'),
    GET_SECTION_STANDARD_UPDATE_FAIL: type('[INSTITUTE] GET SECTION_STANDARD_UPDATE_FAIL'),
    // clear institute List
    INSTITUTE_LIST_CLEAR: type('[INSTITUTE] INSTITUTE_LIST_CLEAR'),
    // verify institute
    VERIFY_INSTITUTE: type('[INSTITUTE] VERIFY_INSTITUTE'),
    VERIFY_INSTITUTE_SUCCESS: type('[INSTITUTE] VERIFY_INSTITUTE_SUCCESS'),
    VERIFY_INSTITUTE_FAIL: type('[INSTITUTE] VERIFY_INSTITUTE_FAIL'),

    // calling Additional module
    ADDITIONAL_MODULE_LIST: type('[INSTITUTE] ADDITIONAL_MODULE_LIST'),
    ADDITIONAL_MODULE_LIST_SUCCESS: type('[INSTITUTE] ADDITIONAL_MODULE_LIST_SUCCESS'),
    ADDITIONAL_MODULE_LIST_FAIL: type('[INSTITUTE] ADDITIONAL_MODULE_LIST_FAIL'),

    // calling additional checked data
    ADDITIONAL_CHECKED: type('[INSTITUTE] ADDITIONAL_CHECKED'),
    // getting language list
    LANGUAGE_LIST: type('[LANGUAGE_LIST] LANGUAGE_LIST'),
    LANGUAGE_LIST_SUCCESS: type('[LANGUAGE_LIST] LANGUAGE_LIST SUCCESS'),
    LANGUAGE_LIST_FAIL: type('[LANGUAGE_LIST] LANGUAGE_LIST FAIL'),
    // approval verification
    INSTITUTE_APPROVAL_VERIFICATION_LIST: type('[INSTITUTE_APPROVAL_VERIFICATION_LIST] INSTITUTE_APPROVAL_VERIFICATION_LIST'),
    INSTITUTE_APPROVAL_VERIFICATION_LIST_SUCCESS: type('[INSTITUTE_APPROVAL_VERIFICATION_LIST] INSTITUTE_APPROVAL_VERIFICATION_LIST SUCCESS'),
    INSTITUTE_APPROVAL_VERIFICATION_LIST_FAIL: type('[INSTITUTE_APPROVAL_VERIFICATION_LIST] INSTITUTE_APPROVAL_VERIFICATION_LIST FAIL'),

    // active inactive List
    ACTIVE_INACTIVE_LIST: type('[ACTIVE_INACTIVE_LIST] ACTIVE_INACTIVE_LIST'),
    ACTIVE_INACTIVE_LIST_SUCCESS: type('[ACTIVE_INACTIVE_LIST] ACTIVE_INACTIVE_LIST SUCCESS'),
    ACTIVE_INACTIVE_LIST_FAIL: type('[ACTIVE_INACTIVE_LIST] ACTIVE_INACTIVE_LIST FAIL'),
    // sms provider list
    SMS_PROVIDER_LIST: type('[SMS_PROVIDER_LIST] SMS_PROVIDER_LIST'),
    SMS_PROVIDER_LIST_SUCCESS: type('[SMS_PROVIDER_LIST] SMS_PROVIDER_LIST SUCCESS'),
    SMS_PROVIDER_LIST_FAIL: type('[SMS_PROVIDER_LIST] SMS_PROVIDER_LIST FAIL'),
    // institute Board list
    BOARD_LIST: type('[BOARD_LIST] BOARD_LIST'),
    BOARD_LIST_SUCCESS: type('[BOARD_LIST] BOARD_LIST SUCCESS'),
    BOARD_LIST_FAIL: type('[BOARD_LIST] BOARD_LIST FAIL'),
    // clear instituteData
    CLEAR_INSTITUTE_DATA: type('[ACTIVE_INACTIVE_LIST] CLEAR_INSTITUTE_DATA'),
    // all institute list
    ALL_INSTITUTE_LIST: type('[ALL_INSTITUTE_LIST] ALL_INSTITUTE_LIST'),
    ALL_INSTITUTE_LIST_SUCCESS: type('[ALL_INSTITUTE_LIST] ALL_INSTITUTE_LIST SUCCESS'),
    ALL_INSTITUTE_LIST_FAIL: type('[ALL_INSTITUTE_LIST] ALL_INSTITUTE_LIST FAIL'),

    // redirect to institute url
    REDIRECT_TO_INSTITUTE: type('[ALL_INSTITUTE_LIST] REDIRECT_TO_INSTITUTE'),
    REDIRECT_TO_INSTITUTE_SUCCESS: type('[ALL_INSTITUTE_LIST] REDIRECT_TO_INSTITUTE SUCCESS'),
    REDIRECT_TO_INSTITUTE_FAIL: type('[ALL_INSTITUTE_LIST] REDIRECT_TO_INSTITUTE FAIL'),

    // INSTITUTE LIST COUNT
    INSTITUTE_LIST_COUNT: type('[INSTITUTE_LIST] INSTITUTE_LIST_COUNT'),
    INSTITUTE_LIST_COUNT_SUCCESS: type('[INSTITUTE_LIST] INSTITUTE_LIST_COUNT SUCCESS'),
    INSTITUTE_LIST_COUNT_FAIL: type('[INSTITUTE_LIST] INSTITUTE_LIST_COUNT FAIL'),
    // INSTITUTE LIST COUNT
    EXPORT_INSTITUTE_LIST: type('[INSTITUTE_LIST]EXPORT_INSTITUTE_LIST'),
    EXPORT_INSTITUTE_LIST_SUCCESS: type('[INSTITUTE_LIST]EXPORT_INSTITUTE_LIST SUCCESS'),
    EXPORT_INSTITUTE_LIST_FAIL: type('[INSTITUTE_LIST]EXPORT_INSTITUTE_LIST FAIL'),
    // export verification Approval
    EXPORT_VERIFICATION_APPROVAL: type('[INSTITUTE_LIST]EXPORT_VERIFICATION_APPROVAL'),
    EXPORT_VERIFICATION_APPROVAL_SUCCESS: type('[INSTITUTE_LIST]EXPORT_VERIFICATION_APPROVAL SUCCESS'),
    EXPORT_VERIFICATION_APPROVAL_FAIL: type('[INSTITUTE_LIST]EXPORT_VERIFICATION_APPROVAL FAIL'),
    // export live Poc
    EXPORT_LIVE_POC: type('[INSTITUTE_LIST]EXPORT_LIVE_POC'),
    EXPORT_LIVE_POC_SUCCESS: type('[INSTITUTE_LIST]EXPORT_LIVE_POC SUCCESS'),
    EXPORT_LIVE_POC_FAIL: type('[INSTITUTE_LIST]EXPORT_LIVE_POC FAIL'),
    // export active inactive govt
    EXPORT_GOVT_ACTIVE: type('[INSTITUTE_LIST]EXPORT_GOVT_ACTIVE'),
    EXPORT_GOVT_ACTIVE_SUCCESS: type('[INSTITUTE_LIST]EXPORT_GOVT_ACTIVE SUCCESS'),
    EXPORT_GOVT_ACTIVE_FAIL: type('[INSTITUTE_LIST]EXPORT_GOVT_ACTIVE FAIL'),
    //
    GET_ACADEMIC_YEAR_SETTING_ACTION: type('[INSTITUTE_LIST] get academic year setting action'),
    GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS: type('[INSTITUTE_LISTth] get academic year setting action Success'),
    GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL: type('[INSTITUTE_LIST] get academic year setting action Fail'),

    CHANGE_ACADEMIC_YEAR_SETTING: type('[INSTITUTE_LIST] change academic year setting'),
    CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS: type('[INSTITUTE_LIST] change academic year setting Success'),
    CHANGE_ACADEMIC_YEAR_SETTING_FAIL: type('[INSTITUTE_LIST] change academic year setting Fail'),

    ACADEMIC_YEAR_LIST_ACTION: type('[INSTITUTE_LIST] academic year list action'),
    ACADEMIC_YEAR_LIST_ACTION_SUCCESS: type('[INSTITUTE_LIST] academic year list action Success'),
    ACADEMIC_YEAR_LIST_ACTION_FAIL: type('[INSTITUTE_LIST] academic year list action Fail'),

    GET_SCHEDULED_INSTITUTE_DETAILS: type('[INSTITUTE_LIST] GET_SCHEDULED_INSTITUTE_DETAILS'),
    GET_SCHEDULED_INSTITUTE_DETAILS_SUCCESS: type('[INSTITUTE_LIST] GET_SCHEDULED_INSTITUTE_DETAILS_SUCCESS'),
    GET_SCHEDULED_INSTITUTE_DETAILS_FAIL: type('[INSTITUTE_LIST] GET_SCHEDULED_INSTITUTE_DETAILS_FAIL'),

    GET_DASHBOARD_COUNT:type('[INSTITUTE_LIST] GET_DASHBOARD_COUNT'),
    GET_DASHBOARD_COUNT_SUCCESS:type('[INSTITUTE_LIST] GET_DASHBOARD_COUNTSUCCESS'),
    GET_DASHBOARD_COUNT_FAIL:type('[INSTITUTE_LIST] GET_DASHBOARD_COUNTFAIL'),
};
// additional checked
export class AdditionalCheckedAction implements Action {
    type = ActionTypes.ADDITIONAL_CHECKED;

    constructor(public payload: any) {
    }
}
// active inactive status
export class ActiveInactiveAction implements Action {
    type = ActionTypes.ACTIVE_INACTIVE_LIST;

    constructor(public payload: any) {
    }
}
export class ActiveInactiveSuccess implements Action {
    type = ActionTypes.ACTIVE_INACTIVE_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class ActiveInactiveFail implements Action {
    type = ActionTypes.ACTIVE_INACTIVE_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// all institute List
export class ALlInstituteAction implements Action {
    type = ActionTypes.ALL_INSTITUTE_LIST;
    constructor(public payload: any) {
    }
}
export class ALlInstituteSuccess implements Action {
    type = ActionTypes.ALL_INSTITUTE_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ALlInstituteFail implements Action {
    type = ActionTypes.ALL_INSTITUTE_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
// sms provider List
export class SmsProviderAction implements Action {
    type = ActionTypes.SMS_PROVIDER_LIST;
}
export class SmsProviderSuccess implements Action {
    type = ActionTypes.SMS_PROVIDER_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SmsProviderFail implements Action {
    type = ActionTypes.SMS_PROVIDER_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
// board List
export class BoardListAction implements Action {
    type = ActionTypes.BOARD_LIST;
}
export class BoardListSuccess implements Action {
    type = ActionTypes.BOARD_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class BoardListFail implements Action {
    type = ActionTypes.BOARD_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
// approval verification List
export class ApprovalVerificationAction implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST;

    constructor(public payload: any) {
    }
}

export class ApprovalVerificationSuccess implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class ApprovalVerificationFail implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL_VERIFICATION_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// create ACTION
export class CreateInstituteAction implements Action {
    type = ActionTypes.CREATE_INSTITUTE;

    constructor(public payload: any) {
    }
}

export class CreateInstituteSuccess implements Action {
    type = ActionTypes.CREATE_INSTITUTE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class CreateInstituteFail implements Action {
    type = ActionTypes.CREATE_INSTITUTE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// additional module list
export class AdditionalModuleListAction implements Action {
    type = ActionTypes.ADDITIONAL_MODULE_LIST;

}

export class AdditionalModuleListSuccess implements Action {
    type = ActionTypes.ADDITIONAL_MODULE_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class AdditionalModuleListFail implements Action {
    type = ActionTypes.ADDITIONAL_MODULE_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// INSTITUTE LIST

export class InstituteListAction implements Action {
    type = ActionTypes.INSTITUTE_LIST;

    constructor(public payload: InstituteListModel) {
    }
}

export class InstituteListSuccess implements Action {
    type = ActionTypes.INSTITUTE_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteListFail implements Action {
    type = ActionTypes.INSTITUTE_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// country List
export class CountryListAction implements Action {
    type = ActionTypes.COUNTRY_LIST;
}

export class CountryListSuccess implements Action {
    type = ActionTypes.COUNTRY_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class CountryListFail implements Action {
    type = ActionTypes.COUNTRY_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// region list
export class RegionListAction implements Action {
    type = ActionTypes.REGION_LIST;

    constructor(public payload: any) {
    }
}

export class RegionListSuccess implements Action {
    type = ActionTypes.REGION_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class RegionListFail implements Action {
    type = ActionTypes.REGION_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// city list
export class CityListAction implements Action {
    type = ActionTypes.CITY_LIST;

    constructor(public payload: any) {
    }
}

export class CityListSuccess implements Action {
    type = ActionTypes.CITY_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class CityListFail implements Action {
    type = ActionTypes.CITY_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// school type Action
export class SchoolTypeBranchAction implements Action {
    type = ActionTypes.SCHOOL_TYPE_BRANCH;
}

export class SchoolTypeBranchSuccess implements Action {
    type = ActionTypes.SCHOOL_TYPE_BRANCH_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class SchoolTypeBranchFail implements Action {
    type = ActionTypes.SCHOOL_TYPE_BRANCH_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// sales Man Api
export class InstituteSalesManAction implements Action {
    type = ActionTypes.INSTITUTE_SALESMAN;

    constructor(public payload: any) {
    }
}

export class InstituteSalesManSuccess implements Action {
    type = ActionTypes.INSTITUTE_SALESMAN_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteSalesManFail implements Action {
    type = ActionTypes.INSTITUTE_SALESMAN_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// institute Details Action
export class InstituteDetailsAction implements Action {
    type = ActionTypes.INSTITUTE_DETAILS;

    constructor(public payload: any) {
    }
}

export class InstituteDetailsSuccess implements Action {
    type = ActionTypes.INSTITUTE_DETAILS_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteDetailsFail implements Action {
    type = ActionTypes.INSTITUTE_DETAILS_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// institute update Action
export class UpdateInstituteAction implements Action {
    type = ActionTypes.UPDATE_INSTITUTE;

    constructor(public payload: any) {
    }
}

export class UpdateInstituteSuccess implements Action {
    type = ActionTypes.UPDATE_INSTITUTE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class UpdateInstituteFail implements Action {
    type = ActionTypes.UPDATE_INSTITUTE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// pocLIve action
export class InstitutePocLiveAction implements Action {
    type = ActionTypes.INSTITUTE_POCLIVE_LIST;

    constructor(public payload: any) {
    }
}

export class InstitutePocLiveSuccess implements Action {
    type = ActionTypes.INSTITUTE_POCLIVE_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstitutePocLiveFail implements Action {
    type = ActionTypes.INSTITUTE_POCLIVE_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// empty update institute variable in reducer
export class EmptyInstituteDetailsVariable implements Action {
    type = ActionTypes.EMPTY_INSTITUTE_DETAILS_VARIABLE;

    constructor(public payload: any) {
    }
}

// institute delete Action
export class DeleteInstituteAction implements Action {
    type = ActionTypes.DELETE_INSTITUTE;

    constructor(public payload: any) {
    }
}

export class DeleteInstituteSuccess implements Action {
    type = ActionTypes.DELETE_INSTITUTE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class DeleteInstituteFail implements Action {
    type = ActionTypes.DELETE_INSTITUTE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// empty update institute variable in reducer
export class EmptyDeleteInstituteVariable implements Action {
    type = ActionTypes.EMPTY_DELETE_INSTITUTE_VARIABLE;

    constructor(public payload: any) {
    }
}
// upload institute logo
export class UploadInstituteLogoAction implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_LOGO;

    constructor(public payload: UploadInstituteLogoModel) {
    }
}

export class UploadInstituteLogoSuccess implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_LOGO_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class UploadInstituteLogoFail implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_LOGO_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// institute approval
export class InstituteApprovalAction implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL;

    constructor(public payload: InstituteApprovalModel) {
    }
}

export class InstituteApprovalSuccess implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteApprovalFail implements Action {
    type = ActionTypes.INSTITUTE_APPROVAL_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// institute renewal
export class InstituteRenewalAction implements Action {
    type = ActionTypes.INSTITUTE_RENEWAL;

    constructor(public payload: InstituteRenewalModel) {
    }
}

export class InstituteRenewalSuccess implements Action {
    type = ActionTypes.INSTITUTE_RENEWAL_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteRenewalFail implements Action {
    type = ActionTypes.INSTITUTE_RENEWAL_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// upload institute FILE
export class UploadInstituteFileAction implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_FILE;

    constructor(public payload: UploadInstituteFileModel) {
    }
}

export class UploadInstituteFileSuccess implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_FILE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class UploadInstituteFileFail implements Action {
    type = ActionTypes.UPLOAD_INSTITUTE_FILE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// empty upload institute FILE
export class EmptyUploadInstituteFile implements Action {
    type = ActionTypes.EMPTY_UPLOAD_INSTITUTE_FILE_VARIABLE;

    constructor(public payload: any) {
    }
}
// section list
export class SectionListAction implements Action {
    type = ActionTypes.SECTION_LIST;
}

export class SectionListSuccess implements Action {
    type = ActionTypes.SECTION_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class SectionListFail implements Action {
    type = ActionTypes.SECTION_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

// standard list
export class StandardListAction implements Action {
    type = ActionTypes.STANDARD_LIST;
}

export class StandardListSuccess implements Action {
    type = ActionTypes.STANDARD_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class StandardListFail implements Action {
    type = ActionTypes.STANDARD_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// section standard update
export class SectionSatandardUpdatetAction implements Action {
    type = ActionTypes.SECTION_STANDARD_UPDATE;
    constructor(public payload: any) {
    }
}

export class SectionSatandardUpdatetSuccess implements Action {
    type = ActionTypes.SECTION_STANDARD_UPDATE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class SectionSatandardUpdatetFail implements Action {
    type = ActionTypes.SECTION_STANDARD_UPDATE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

// section standard update
export class GetSectionStandardUpdateAction implements Action {
    type = ActionTypes.GET_SECTION_STANDARD_UPDATE;
    constructor(public payload: any) {
    }
}

export class GetSectionStandardUpdateSuccess implements Action {
    type = ActionTypes.GET_SECTION_STANDARD_UPDATE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class GetSectionStandardUpdateFail implements Action {
    type = ActionTypes.GET_SECTION_STANDARD_UPDATE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// clear institute list
export class ClearInstituteList implements Action {
    type = ActionTypes.INSTITUTE_LIST_CLEAR;
}
// verify institute
export class VerifyInstituteAction implements Action {
    type = ActionTypes.VERIFY_INSTITUTE;

    constructor(public payload: any) {
    }
}

export class VerifyInstituteSuccess implements Action {
    type = ActionTypes.VERIFY_INSTITUTE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class VerifyInstituteFail implements Action {
    type = ActionTypes.VERIFY_INSTITUTE_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// language List
export class LanguageListAction implements Action {
    type = ActionTypes.LANGUAGE_LIST;
}

export class LanguageListSuccess implements Action {
    type = ActionTypes.LANGUAGE_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class LanguageListFail implements Action {
    type = ActionTypes.LANGUAGE_LIST_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}
// clear get Institute Details
export class InstituteDataClear implements Action {
    type = ActionTypes.CLEAR_INSTITUTE_DATA;
}

// REDIRECT TO INSTITUTE URL
export class RedirectToInstituteAction implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE;
    constructor(public payload: any) {
    }
}
export class RedirectToInstituteSuccess implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class RedirectToInstituteFail implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

// INSTITUTE LIST COUNT

export class InstituteListCountAction implements Action {
    type = ActionTypes.INSTITUTE_LIST_COUNT;
    constructor(public payload: InstituteListModel) {
    }
}
export class InstituteListCountSuccess implements Action {
    type = ActionTypes.INSTITUTE_LIST_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class InstituteListCountFail implements Action {
    type = ActionTypes.INSTITUTE_LIST_COUNT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportInstituteAction implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_LIST;
    constructor(public payload: InstituteListModel) {
    }
}
export class ExportInstituteSuccess implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportInstituteFail implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class ExportVerificationAction implements Action {
    type = ActionTypes.EXPORT_VERIFICATION_APPROVAL;
    constructor(public payload: InstituteListModel) {
    }
}
export class ExportVerificationSuccess implements Action {
    type = ActionTypes.EXPORT_VERIFICATION_APPROVAL_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportVerificationFail implements Action {
    type = ActionTypes.EXPORT_VERIFICATION_APPROVAL_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class ExportLivePocAction implements Action {
    type = ActionTypes.EXPORT_LIVE_POC;
    constructor(public payload: InstituteListModel) {
    }
}
export class ExportLivePocSuccess implements Action {
    type = ActionTypes.EXPORT_LIVE_POC_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportLivePocFail implements Action {
    type = ActionTypes.EXPORT_LIVE_POC_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class ExportGovtAction implements Action {
    type = ActionTypes.EXPORT_GOVT_ACTIVE;
    constructor(public payload: InstituteListModel) {
    }
}
export class ExportGovtSuccess implements Action {
    type = ActionTypes.EXPORT_GOVT_ACTIVE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportGovtFail implements Action {
    type = ActionTypes.EXPORT_GOVT_ACTIVE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
//get commyear sitting
export class GetAcademicYearSettingAction implements Action {
    type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION;
    constructor(public payload = null) { }
}
export class GetAcademicYearSettingActionSuccess implements Action {
    type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_SUCCESS;
    constructor(public payload: any) { }
}
export class GetAcademicYearSettingActionFail implements Action {
    type = ActionTypes.GET_ACADEMIC_YEAR_SETTING_ACTION_FAIL;
    constructor(public payload: any) { }
}
// change commyear sitting
export class ChangeAcademicYearSetting implements Action {
    type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING;

    constructor(public payload: any) { }
}

export class ChangeAcademicYearSettingSuccess implements Action {
    type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_SUCCESS;

    constructor(public payload: ResponsInterface) { }
}

export class ChangeAcademicYearSettingFail implements Action {
    type = ActionTypes.CHANGE_ACADEMIC_YEAR_SETTING_FAIL;

    constructor(public payload: any) { }
}

export class AcademicYearListAction implements Action {
    type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION;
    constructor(public payload = null) { }
}
export class AcademicYearListActionSuccess implements Action {
    type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION_SUCCESS;
    constructor(public payload: any) { }
}
export class AcademicYearListActionFail implements Action {
    type = ActionTypes.ACADEMIC_YEAR_LIST_ACTION_FAIL;
    constructor(public payload: any) { }
}

export class GetScheduledInstituteDetails implements Action {
    type = ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS;

    constructor(public payload: any) {
    }
}

export class GetScheduledInstituteDetailsSuccess implements Action {
    type = ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class GetScheduledInstituteDetailsFail implements Action {
    type = ActionTypes.GET_SCHEDULED_INSTITUTE_DETAILS_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}

export class getDashBoardCount implements Action {
    type = ActionTypes.GET_DASHBOARD_COUNT;

    constructor(public payload: any) {
    }
}

export class getDashBoardCountSuccess implements Action {
    type = ActionTypes.GET_DASHBOARD_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}

export class getDashBoardCountFail implements Action {
    type = ActionTypes.GET_DASHBOARD_COUNT_FAIL;

    constructor(public payload: ResponsInterface) {
    }
}





export type Actions
    = CreateInstituteAction
    | ExportLivePocAction
    | ExportLivePocSuccess
    | ExportLivePocFail
    | ExportGovtAction
    | ExportGovtFail
    | ExportGovtSuccess
    | ExportVerificationAction
    | ExportVerificationSuccess
    | ExportVerificationFail
    | ExportInstituteAction
    | ExportInstituteSuccess
    | ExportInstituteFail
    | CreateInstituteSuccess
    | CreateInstituteFail
    | InstituteListAction
    | InstituteListSuccess
    | InstituteListFail
    | CountryListAction
    | CountryListSuccess
    | CountryListFail
    | RegionListAction
    | RegionListSuccess
    | RegionListFail
    | CityListAction
    | CityListSuccess
    | CityListFail
    | SchoolTypeBranchAction
    | SchoolTypeBranchSuccess
    | SchoolTypeBranchFail
    | InstituteSalesManAction
    | InstituteSalesManSuccess
    | InstituteSalesManFail
    | InstituteDetailsAction
    | InstituteDetailsSuccess
    | InstituteDetailsFail
    | UpdateInstituteAction
    | UpdateInstituteSuccess
    | UpdateInstituteFail
    | InstitutePocLiveAction
    | InstitutePocLiveSuccess
    | InstitutePocLiveFail
    | EmptyInstituteDetailsVariable
    | DeleteInstituteAction
    | DeleteInstituteSuccess
    | DeleteInstituteFail
    | EmptyDeleteInstituteVariable
    | UploadInstituteLogoAction
    | UploadInstituteLogoSuccess
    | UploadInstituteLogoFail
    | InstituteApprovalAction
    | InstituteApprovalSuccess
    | InstituteApprovalFail
    | InstituteRenewalAction
    | InstituteRenewalSuccess
    | InstituteRenewalFail
    | UploadInstituteFileSuccess
    | UploadInstituteFileFail
    | UploadInstituteFileAction
    | EmptyUploadInstituteFile
    | SectionListAction
    | SectionListSuccess
    | SectionListFail
    | StandardListAction
    | StandardListSuccess
    | StandardListFail
    | SectionSatandardUpdatetAction
    | SectionSatandardUpdatetSuccess
    | SectionSatandardUpdatetFail
    | GetSectionStandardUpdateAction
    | GetSectionStandardUpdateSuccess
    | GetSectionStandardUpdateFail
    | ClearInstituteList
    | VerifyInstituteAction
    | VerifyInstituteSuccess
    | VerifyInstituteFail
    | AdditionalModuleListAction
    | AdditionalModuleListSuccess
    | AdditionalModuleListFail
    | AdditionalCheckedAction
    | LanguageListAction
    | LanguageListSuccess
    | LanguageListFail
    | ApprovalVerificationAction
    | ApprovalVerificationSuccess
    | ApprovalVerificationFail
    | ActiveInactiveAction
    | ActiveInactiveSuccess
    | ActiveInactiveFail
    | InstituteDataClear
    | SmsProviderAction
    | SmsProviderSuccess
    | SmsProviderFail
    | BoardListAction
    | BoardListSuccess
    | BoardListFail
    | ALlInstituteAction
    | ALlInstituteSuccess
    | ALlInstituteFail
    | RedirectToInstituteAction
    | RedirectToInstituteSuccess
    | RedirectToInstituteFail
    | InstituteListCountAction
    | InstituteListCountSuccess
    | InstituteListCountFail;
